import React, { useEffect, useState } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { Card, Col, Row, Spin,Tabs,Empty } from "antd";
import { RefreshComponet } from "../../utility/UserDetails";
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
function Services() {
  const {t} = useTranslation();
    const { TabPane } = Tabs;
const { Meta } = Card;
    const [warningLoading, setWarningLoading] = useState(false);
    const [episodeList, setEpisodeList] = useState([]);
    const [agreementList, setAgreementList] = useState([]);

    useEffect(() => {
        setWarningLoading(true);
        getServices()
    }, []);


    const getServices = () => {
        setWarningLoading(true);
        let endpoint = "client/services";
        AxiosInstance.get(endpoint, '')
            .then(res => {
                setWarningLoading(false);
                if(res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)
                if (res.data.success) {
                    // Toaster("success", "Success", res.message);
                    setAgreementList(res.data.data?.service_agreement);
                    setEpisodeList(res.data.data?.assessment);
                } else {
                    setAgreementList([])
                    Toaster("error", "Error", res.data.message);
                }

            })
            .catch(error => {
                setWarningLoading(false);
            });
    }

    const handleTabClick = (key) => {
        // setActiveTab(key);
        // resetState();
      };
  return (
    <div>
          <Spin spinning={warningLoading} fullscreen  size='large' />
      <Tabs defaultActiveKey="1" onChange={handleTabClick} centered size="large" type="card">
        <TabPane
          tab={
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span>{t('Agreements')}</span>
            </div>
          }
          key="1"
        >
              <Row
                gutter={[16, 16]}
                className="mt-3"
                style={{ margin: "0px 0px" }}
              >
                {agreementList.map((item, index) => (
                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                      <Card
                        style={{
                          background: "#f0f2f5",
                          borderLeftWidth: "3px",
                          fontWeight: "bold",
                          position: "relative",
                          boxShadow: "none",
                        }}
                      >
                        <Meta
                          title={
                            <div style={{}}>
                              <div
                                style={{ marginTop: 5, marginBottom:5, marginLeft: '5px' }}>
                                <div>
                                  <span>
                                    {" "}
                                    {item.desc}
                                  </span>
                                  <span
                                    style={{ fontSize: "0.7rem", color: "#8546A7" }}
                                  >
                                    {" "}
                                    {item.name}
                                  </span>
                                </div>
                                <div>
                                  <span>
                                  {dayjs(item?.sDate).format('MM/DD/YYYY')} -   {dayjs(item?.eDate).format('MM/DD/YYYY')} 
                                  </span>
                                </div>
                              
                                <div
                            className="progress-wrapper"
                            style={{ marginBottom: "10px", fontWeight:'bold' }}
                          >
                              <div
                                className="progress-bar"
                                style={{ width: `${(item.used / item?.tHours) * 100}%` }}
                              >
                                    <span>
                                    {t('Used')} - {(item.used / item?.tHours * 100).toFixed(2)} % / {t('TotalHrs')} - {item?.tHours}
                                    </span>
                              
                              </div>
                          </div>


                                <div
                            className="progress-wrapper"
                            style={{ marginBottom: "10px", fontWeight:'bold' }}
                          >
                              <div
                                className="progress-bar"
                             style={{ width: `${(item.rem / item?.tHours) * 100}%` }}
                              >
                                    <span>
                                    {t('Remaining')} - {(item.rem / item?.tHours * 100).toFixed(2)} % / {t('TotalHrs')} -{" "}
      {item?.tHours}
                                    </span>
                              
                              </div>
                          </div>


                              
                              </div>
                            </div>
                          }
                        />
                      </Card>
                  </Col>
                ))}
              </Row>
            {agreementList.length === 0 && (
              <Empty description={t('NoDataFound')} />
            )}
        
        </TabPane>
        <TabPane
          tab={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span>{t('Episodes')}</span>
            </div>
          }
          key="2"
        >
        
        
              <Row
                gutter={[16, 16]}
                className="mt-3"
                style={{ margin: "0px 0px" }}
              >
                {episodeList.map((item, index) => (
                  <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                  
                      <Card
                        style={{
                          background: "#f0f2f5",
                          borderLeftWidth: "3px",
                          fontWeight: "bold",
                          position: "relative",
                          boxShadow: "none",
                        }}
                      >
                        <Meta
                          title={
                            <div style={{}}>
                              <div
                               style={{ marginTop: 5, marginBottom:5, marginLeft: '5px' }}>
                                <div>
                                  <span>
                                    {" "}
                                    {dayjs(item?.sDate).format('MM/DD/YYYY')} -   {dayjs(item?.eDate).format('MM/DD/YYYY')} 
                                  </span>
                                  <span
                                    style={{ fontSize: "0.7rem", color: "#8546A7" }}
                                  >
                                    {item.desc} - {item.name}
                                  </span>
                                </div>
                                <div>
                                Dr Name:
                                  <span style={{ color: "#8546A7" }}>
                                    { } {item.drName}
                                  </span>
                                </div>
                                <div>
                                NPI:
                                  <span>
                                    { } {item.npi}
                                  </span>
                                </div>
                                <div>
                                {t('Contact')}
                                  <span>
                                    { } {item.phone}
                                  </span>
                                </div>

                                  <div>
                                  <span>
                                    {item?.clientId?.name}
                                  </span>
                                </div>

                              </div>
                            </div>
                          }
                        />

                      </Card>
                  </Col>
                ))}
              </Row>
            {episodeList.length === 0 && (
              <Empty description={t('NoDataFound')} />
            )}
        
        </TabPane>
      
      </Tabs>
    </div>
  )
}

export default Services