import React, { useState, useEffect } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import moment from "moment";
import dayjs from "dayjs";
import {
    Form,
    Input,
    Button,
    DatePicker,
    Row,
    Col,
    Card,
    Spin,
    Divider,
    Badge
} from "antd";
import { useTranslation } from "react-i18next";

const Vacation = () => {
    const {t} = useTranslation();
    const { Meta } = Card;
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    // const [formValues, setFormValues] = useState({});
    const [VacationData, setVacationData] = useState([]);
    const [warningLoading, setWarningLoading] = useState(false);
    const today = moment(new Date()).format("DD/MM/YYYY");

    const Formfield = [
        {
            name: "sDate",
            label: t('Start Date'),
            type: "date",
            min_date: moment(new Date()).subtract(1, "months"),
            max_date: moment(new Date()).add(1, "months"),
            value: "",
            placeholder: t('Start Date'),
            required: true,
        },
        {
            name: "eDate",
            label: t('End Date'),
            type: "date",
            min_date: moment(new Date()).subtract(1, "months"),
            max_date: moment(new Date()).add(1, "months"),
            value: "",
            placeholder: t('End Date'),
            required: true,
        },
        {
            name: "empNote",
            label: t('Note'),
            type: "TextArea",
            placeholder: t('Enter Note'),
            required: true,
        },
    ];

    useEffect(() => {
        form.resetFields();
        
        form.setFieldsValue({
            sDate: dayjs(today, "DD/MM/YYYY"),
            eDate: dayjs(today, "DD/MM/YYYY"),
        });
        getVacation();
    }, []);

    const getColor = (c) => {
        switch (c.status) {
          case "New":
            return "teal";
          case "Approved":
            return "#16E364";
          case "Pending":
            return "#BCCE07";
          case "Completed":
            return "rgb(50, 205, 50)";
          case "Ended":
            return "#808080";
          case "Cancelled":
            return "#FF0000";
          case "Inprogress":
            return "#F2AA4CFF";
          case "Finished":
            return "#13c2c2";
          case "Unverified":
            return "#000000";
          default:
            return "#1E90FF";
        }
      };
    const renderFormField = (field) => {
        const {
            type,
            name,
            label,
            TextArea,
            placeholder,
            min_date,
            max_date,
        } = field;
        switch (type) {
            case "date":
                return (
                    <Form.Item
                        key={name}
                        label={label}
                        name={name}
                        rules={[{ required: field.required, message: `Please  ${label}` }]}
                    >
                        <DatePicker
                            placeholder={placeholder}
                            format={"MM/DD/YYYY"}
                            disabledDate={current => current && current < dayjs().startOf('day')}
                            //   disabledDate={(current) => {
                            //     // Disable dates before the minimum date or after the maximum date
                            //     return current && (current < min_date || current > max_date);
                            //   }}
                            size="large"
                        />
                    </Form.Item>
                );

            case "TextArea":
                return (
                    <Form.Item
                        key={name}
                        label={label}
                        name={name}
                        rules={[
                            { required: field.required, message: `Please enter ${label}` },
                        ]}
                    >
                        <Input.TextArea type={TextArea} size="large" autoSize placeholder={placeholder} />
                    </Form.Item>
                );
            default:
                return null;
        }
    };

    const handleFormChange = (allValues) => {
        if (allValues.sDate && allValues.sDate.$d)
            allValues.sDate = dayjs(allValues.sDate.$d).format("YYYY-MM-DD");
        // setFormValues(allValues);
    };
    // const handleReset = (type = "") => {
    //     if (!type) {
    //         form.resetFields();
    //         setFormValues({});
    //     }
    // };
    const onFinish = (values) => {
        if (values.sDate > values.eDate) {
            Toaster("error", "Error", t('Start Date must be less than or equal to End Date'));
            return;
          }
        setLoading(true);
        values.sDate = dayjs(values.sDate.$d).format("YYYY-MM-DD");
        values.eDate = dayjs(values.eDate.$d).format("YYYY-MM-DD");
        const url = "emp/vacation-request";
        AxiosInstance.post(url, values)
            .then((res) => {
                setLoading(false);
                if (res.data.success) {
                    Toaster("success", "Success", res.data.message);
                    form.resetFields();
                    form.setFieldsValue({
                        sDate: dayjs(today, "DD/MM/YYYY"),
                        eDate: dayjs(today, "DD/MM/YYYY"),
                    });
                    getVacation();
                    console.log(res.data, "res.data");
                } else {
                    Toaster("error", "Error", res.data.message);
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error("Error fetching data:", error);
            });
    };
    const getVacation = () => {
        setWarningLoading(true);
        let endpoint = "emp/vacation-request";
        AxiosInstance.get(endpoint)
            .then(res => {
                //   if(res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)
                setWarningLoading(false);
                if (res.data.success) {
                    // Toaster("success", "Success", res.message);
                    setVacationData(res.data.data.reverse());
                } else {
                    setVacationData([])
                    Toaster("error", "Error", res.data.message);
                }

            })
    }
    return (
        <div>
            <Form
             className='vacation-info'
                onValuesChange={handleFormChange}
                form={form}
                onFinish={onFinish}
                layout="vertical"
                style={{ padding: "0px 100px" }}
            >
                <Row gutter={[16, 0]}>
                    {Formfield.map((field, index) => (
                        <Col key={index} xs={24} sm={8}>
                            {renderFormField(field)}
                        </Col>
                    ))}
                </Row>
                <Form.Item style={{ textAlign:'end'  }}>
                    {/* <Button
                        className="ant-btn-form"
                        style={{ marginRight: "10px" }}
                        type="primary" danger
                        onClick={() => handleReset("")}
                    >
                        Reset
                    </Button> */}
                    <Button style={{ width:'20%'  }}  className="ant-btn-form" type="primary" htmlType="submit" loading={loading}>
                    {t('Submit')}
                    </Button>
                </Form.Item>
            </Form>
              <Divider></Divider>
            <Spin spinning={warningLoading} fullscreen size='large' />
            <Row
                gutter={[16, 16]}
                className="mt-3"
                style={{ margin: "10px 0px 100px" }}
            >
                {VacationData.map((item, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={16} xl={6}>
                        <Badge.Ribbon
                            placement="start"
                            text={t(item?.status)}
                            color={getColor(item)}
                        >
                            <Card
                                style={{
                                    background: "#f0f2f5",
                                    borderLeftWidth: "3px",
                                    borderLeftColor: getColor(item),
                                    fontWeight: "bold",
                                    position: "relative",
                                    boxShadow: "none",
                                }}
                            >
                                <Meta
                                    title={
                                        <div style={{}}>
                                            <div
                                                style={{ marginTop: 35, marginLeft: '5px' }}>
                                                <div>
                                                    <span>
                                                        {" "}
                                                        {dayjs(item?.sDate).add(1, 'day').format('MM/DD/YYYY')} - {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    description={
                                        <div style={{ marginBottom: "5px", marginLeft: '5px',marginTop:'-9px' }}>
                                            {item.empNote}
                                        </div>
                                    }
                                />
                            </Card>
                        </Badge.Ribbon>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default Vacation