import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';

const { Option } = Select;
const GMap = ({markers}) => {
    console.log(markers)
  const googleMapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [stepsLegs, setStepsLegs] = useState({});
  const [travelMode, setTravelMode] = useState('DRIVING');
  const [err, setErr] = useState('');

  useEffect(() => {
    const googleMapScript = document.createElement('script');
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=geometry&callback=initMap`;
    googleMapScript.async = true;
    googleMapScript.defer = true;
    window.initMap = initGoogleMap;

    document.head.appendChild(googleMapScript);

    return () => {
      document.head.removeChild(googleMapScript);
    };
  }, []);

  const initGoogleMap = () => {
    const googleMap = new window.google.maps.Map(googleMapRef.current, {
      center: { lat: markers[0].lat, lng:  markers[0].lng },
    //   center: { lat: 33.6844, lng:  73.0479 },
      zoom: 8,
    });
    setMap(googleMap);
  };

  useEffect(() => {
    if (map && travelMode) {
      calculateDirections();
    }
  }, [map, travelMode]);
  const calculateDirections = () => {
    console.log(markers[1].lat,markers[1].lat)
    // const uLoc = new window.google.maps.LatLng(33.6844, 73.0479);
    const uLoc = new window.google.maps.LatLng(markers[1].lat, markers[1].lng);
    const cLoc = new window.google.maps.LatLng(markers[0].lat, markers[0].lng);
    // const cLoc = new window.google.maps.LatLng(34.1986, 72.0404);
    const request = {
      origin: uLoc,
      destination: cLoc,
      travelMode: travelMode || 'DRIVING', // or 'WALKING', 'BICYCLING', 'TRANSIT'
      provideRouteAlternatives: true,
      optimizeWaypoints: true
    };
    const directionsService = new window.google.maps.DirectionsService();
    const directionsRenderer = new window.google.maps.DirectionsRenderer();
    directionsService.route(request, (response, status) => {
        if (status === 'OK') {
            directionsRenderer.setDirections(response);
            directionsRenderer.setMap(map);
            // Extract distance and duration
            debugger
            const route = response.routes[0];
            const leg = route.legs[0];
            console.log("Distance: " + leg.steps[0].travel_mode);
            setStepsLegs(leg);
            setErr('');
          } else {
            setErr('Directions request returned no results.')
            console.error('Directions request failed:', status);
          }
    });
  };

  const handleTravelModeChange = (value) => {
    setTravelMode(value);
  };
  return (
    <>
     {!err && stepsLegs.steps ? ( <p>TravelMode : {stepsLegs.steps[0].travel_mode} Distance: {stepsLegs.distance.text} Duration: {stepsLegs.duration.text}</p>) : null }
     {/* {stepsLegs ? ( <p>A : {stepsLegs.start_address.split(' ').slice(0, 3).join(' ')} B : {stepsLegs.end_address.split(' ').slice(0, 3).join(' ')}</p>) : null } */}
    {err ? <p>{err}</p> : null }
     <div style={{marginBottom:6}}>
        <Select defaultValue="DRIVING" onChange={handleTravelModeChange} style={{ width: 120 }}>
          <Option value="DRIVING">Driving</Option>
          <Option value="WALKING">Walking</Option>
          <Option value="BICYCLING">Bicycling</Option>
          <Option value="TRANSIT">Transit</Option>
        </Select>
      </div>
     {/* <h2>Directions</h2>
         {stepsLegs.steps ? ( <p>travelMode : {stepsLegs.steps[0].travel_mode} Distance: {stepsLegs.distance.text} Duration: {stepsLegs.duration.text}</p>) : null }
          {stepsLegs.steps ? ( <ol>
          {stepsLegs?.steps.map((step, index) => (
            <li key={index}>{step.instructions}</li>
          ))}
        </ol>) : null } */}
     <div ref={googleMapRef}  style={{ maxWidth: '100%', height: '300px' }}>
      {/* Map will be rendered here */}
    </div>
    </>

  );
};

export default GMap;
