import React, { useEffect, useState } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { RefreshComponet } from "../../utility/UserDetails";
import { Card, Col, Row, Spin,Tabs,Empty } from "antd";
import { useTranslation } from 'react-i18next';
function CarePlans() {
  const {t} = useTranslation();
  const { TabPane } = Tabs;
  const { Meta } = Card;
      const [warningLoading, setLoading] = useState(false);
      const [carePlanTabs, setCarePlanTabs] = useState([]);
      const [careplan, setcareplan] = useState([]);
      
      useEffect(() => {
        let UserInfo = JSON.parse(localStorage.getItem("USER_INFO")) || "";
        if (UserInfo) {
          getCarePlanTabs(UserInfo.companyId._id);
        }
      }, []);
      const getCarePlanbyType = (type = "") => {
        const local = "client/careplan-client";
        const params = { type: type };
        setLoading(true);
        AxiosInstance.get(local, { params })
          .then((response) => {
            setLoading(false);
            const data = response.data;
            if (data.success) {
              if (data.data.careplan) {
                // setOutComes([]);
              //   data.data.careplan.map(x => {
              //   if(x.task) x.task = x.task.split(":")[0];
              //  });
               setcareplan(data.data.careplan)
              } else {
                // careplan([]);
                setcareplan(data.data.outcome_section);
              }
            } else {
              setcareplan([]);
              // Handle error or display message
            }
          })
          .catch((error) => {
            setLoading(false);
            Toaster("error", "Error", error.message);
            // Handle error or display message
          });
      };

      const getCarePlanTabs = (id) => {
        const local = "emp/careplan-company";
        const params = { cid: id };
        setLoading(true);
        AxiosInstance.get(local, { params })
          .then((response) => {
            setLoading(false);
            if(response.data.dirtyBit) RefreshComponet(response.data.dirtyBit)
            const data = response.data;
            if (data.success) {
              setCarePlanTabs(data.data.rows);
              getCarePlanbyType(data.data.rows[0].type);
            } else {
              setCarePlanTabs([]);
            }
          })
          .catch((error) => {
            setLoading(false);
            Toaster("error", "Error", error.message);
          });
      };
      const handleTabClick = (key, tabType) => {
        getCarePlanbyType(tabType);
      };
  
    return (
      <div>
         <Spin spinning={warningLoading} fullscreen  size='large' />
      <Tabs
        defaultActiveKey="0"
        onChange={(key) => handleTabClick(key, carePlanTabs[key]?.type)}
        centered
        size="large"
        type="card"
      >
        {carePlanTabs.map((tab, index) => (
          <TabPane tab={tab.type} key={index.toString()}>
          {tab.type ==='PCA' ?<Row
            gutter={[16, 16]}
            className="mt-3"
            style={{ margin: "0px 0px" }}
          >
            {careplan.map((item, index) => (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={8}
              >
                <Card
                  style={{
                    background: "#f0f2f5",
                    borderLeftWidth: "3px",
                    fontWeight: "bold",
                    position: "relative",
                    boxShadow: "none",
                  }}
                >
                  <Meta
                    title={
                      <div style={{}}>
                        <div
                          style={{
                            marginTop: 5,
                            marginBottom: 5,
                            marginLeft: "5px",
                          }}
                        >
                          <div style={{display:'flex',flexDirection:'column',}}>
                            <span> {item.code}</span>
                            <span
                              style={{
                                fontSize: "0.7rem",
                                color: "#8546A7",
                                textWrap:'pretty'
                              }}
                            >
                              {" "}
                              {item?.instruction || item?.task}
                              {/* {item.instruction} */}
                            </span>
                          </div>   
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>:<Row
            gutter={[16, 16]}
            className="mt-3"
            style={{ margin: "0px 0px" }}
          >
            {careplan.map((item, index) => (
              <Col
                key={index}
                xs={24}
                sm={24}
                md={12}
                lg={6}
                xl={8}
              >
                <Card
                  style={{
                    background: "#f0f2f5",
                    borderLeftWidth: "3px",
                    fontWeight: "bold",
                    position: "relative",
                    boxShadow: "none",
                  }}
                >
                  <Meta
                    title={
                      <>
                          <div style={{padding:'5px 6px' , marginTop:10}}>
                            <span style={{textWrap:'pretty'}}>
                             {item.outcome}
                            </span>
                          </div>      
                          <div style={{marginBottom:10, display:'flex',flexDirection:'row',justifyContent:'space-between',fontSize: "1rem", 
                                color: "#8546A7",padding:'0 6px'}}>
                            <span  style={{textWrap:'pretty'}} > {item.instruction}</span>
                            <span                                          >
                              {" "}
                              {item.qualifier}
                            </span>
                          </div>                                 
                    </>
                    }
                  />
                </Card>
              </Col>
            ))}
          </Row>}
          {careplan.length === 0 && (
            <Empty description={t('NoDataFound')} />
          )}
          </TabPane>
        ))}
      </Tabs>

      
    </div>
    )
  }

export default CarePlans