import React, { useEffect, useState } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { RefreshComponet } from "../../utility/UserDetails";
import { useHistory } from "react-router-dom";
import {
  Input,
  Button,
  Row,
  Col,
  Card,
  Spin,
  Avatar,
  Tag,
  Flex,
  Empty
} from "antd";
import {
  EyeOutlined,
  SearchOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
const { Meta } = Card;

const Clients = () => {
  const {t} = useTranslation();
  const [ClientList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const history = useHistory(); // Initialize useHistory

  useEffect(() => {
    getClients();
  }, []);

  const getClients = () => {
    const local = "emp/my-client";
    setLoading(true);
    AxiosInstance.get(local)
      .then((response) => {
        setLoading(false);
        if(response.data.dirtyBit) RefreshComponet(response.data.dirtyBit)
        const data = response.data;
        if (data.success) {
          setClientList(data.data);
        } else {
          setClientList([]);
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
        // Handle error or display message
      });
  };

  const onView = (id,type) => {
    if(type === 'forms'){
      history.push(`/Clients/clinical/${id}`); 
    }
    else{
      history.push(`/Clients/client/${id}`); 
    }
  };
  const filteredClientList = ClientList.filter(
    (item) => {
        const searchParts = searchValue.toLowerCase().split(" ");
        const fNameLower = item.fName.toLowerCase();
        const lNameLower = item.lName.toLowerCase();
    
        // Check if any part of fName or lName includes any part of searchValue
        const fNameMatch = searchParts.every(part =>
          fNameLower.split(' ').some(namePart => namePart.includes(part))
        );
    
        const lNameMatch = searchParts.every(part =>
          lNameLower.split(' ').some(namePart => namePart.includes(part))
        );
    
        return fNameMatch || lNameMatch;
      }
  );

  return (
    <>
        <Spin
        size="large"
        spinning={loading}
        fullscreen
        tip={t('Fetching Your Data')}
      />
      <Flex
        style={{
          marginBottom: "15px",
          padding: "10px",
          background: "#91caff",
        }}
        justify="space-between"
      >
        <Input          
          size="small"
          placeholder={t('SearchByClientName')}
          prefix={<SearchOutlined className="site-form-item-icon" />}
          suffix={
            // Conditional rendering of the cross icon
            searchValue.length > 0 && (
              <Button
                type="link"
                onClick={() => setSearchValue("")} // Clear search value
                style={{ marginRight: -7, padding: 0 }}
              >
                <CloseOutlined />
              </Button>
            )
          }
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
        />
      </Flex>
      <Row gutter={[16, 16]} className="mt-3" style={{ margin: "0px 10px" }}>
        {filteredClientList.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
            <Card
              className="ant-tabs-tabpane"
              style={{
                background: "#f0f2f5",
                borderLeftWidth: "3px",
                borderLeftColor: "#1E90FF",
              }}
            >
              <Meta
                title={
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        marginTop: 15,
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <Avatar
                        size={50}
                        style={{
                          backgroundColor: "#87d068",
                        }}
                      >
                        {/* icon={<UserOutlined />} */}
                        <>
                          {item?.fName?.charAt(0) || ""}
                          {item?.lName?.charAt(0) || ""}
                        </>
                      </Avatar>
                    </div>
                    <div
                      style={{
                        marginTop: 15,
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      <div>
                        {item?.fName} {item?.lName}
                      </div>
                      <div>
                      {t('Phone')}:
                        <span style={{ color: "#8546A7", fontSize: "0.9rem" }}>
                          {" "}
                          {item?.phone1.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}
                        </span>
                      </div>
                      <div style={{ cursor: "pointer" }}>
                        <Tag color="#2db7f5"  onClick={() => onView(item._id,'forms')}>{t('Clinical Forms')}</Tag>
                        <Tag color="#87d068" onClick={() => onView(item._id,'')}>
                          <EyeOutlined />
                        </Tag>
                      </div>
                    </div>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
      <Row style={{marginTop:'10rem',flexDirection:'row',justifyContent:'center'}}>
      {(filteredClientList.length === 0) && !loading && (
              <Empty description={t('NoDataFound')} style={{textAlign:'center'}} />
            )}
      </Row>
  
    </>
  );
};

export default Clients;
