import React, { useRef, useState,useEffect } from "react";
import PinField from "react-pin-field";
import cn from "classnames";
import { useHistory } from "react-router-dom";
import { Toaster } from "../utility/Toaster";
import "../assets/styles/app.css";
import logo from "../assets/images/pinLogo.png";
import { Layout, Button, Typography, Form, Input,Divider,message } from "antd";
// import Video from "../utility/Video";
import { SmileOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from "react-i18next";

const { Title, Text } = Typography;
const { Content } = Layout;

function Pin() {
  let incorrectAttempts = 0;
  const [completed, setCompleted] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const localPin = localStorage.getItem("pin");
  const pinFieldRef = useRef();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === 'POP') {
        // If the user tries to navigate back, stay on the current route
        return false;
      }
    });

    return () => unblock(); // Cleanup the blocking effect when the component unmounts
  }, [history]);
  const handlePinComplete = (pin) => {
    if(!localPin){
    setCompleted(true);
    localStorage.setItem("pin", pin);
    setTimeout(() => {
      history.push("/home");
    }, 100);
    }else{
       if(localPin === pin) {
        history.push("/home");
        Toaster("success", "Success", t('Pin Accepted'),<SmileOutlined style={{ color: '#108ee9' }} />);
       }
       else {
        incorrectAttempts++;
        pinFieldRef.current.forEach(input => (input.value = ""));
        pinFieldRef.current[0].focus()
        // Toaster('error','Please Enter Valid PIN','');
        if (incorrectAttempts === 3) {
         // console.log('You have entered the wrong PIN 3 times. Redirecting to logout...');
          logOutUser();
          return;
        }
        const attemptsRemaining = 3 - incorrectAttempts;
        message.open({
          type: 'warning',
          content: `${t("Incorrect PIN. You have")} ${attemptsRemaining} ${t("attempts remaining.")}`,
        });
      }
    }
  };
  const logOutUser = () => {
    history.replace("/sign-in");
    localStorage.clear();
  };
  return (
    <>
      {/* <Video /> */}
      <Layout className="layout-default layout-signin">
        <Content>
          <div className="py-4 text-center">
            <img
              alt="logo"
              className="pin-logo"
              src={logo}
            />
            <div className="container text-center">
            {/* <Title style={{ margin: 0, textAlign: "center" }} underline level={2}>
            RevivingCare
          </Title> */}
          <Title
            style={{ marginTop: '80px', textAlign: "center" }}
            underline
            level={5}
          >
            {localPin ? t('Please Enter Your Quick Access PIN') : t('Please Create PIN For Quick Access')}
          </Title>
              <div className="pin-field-container">
                <PinField
                  ref={pinFieldRef}
                  className={cn("pin-field", { complete: completed })}
                  onComplete={(e) => handlePinComplete(e)}
                  validate="0123456789"
                  autoFocus
                  disabled={completed}
                  length={4}
                  autoComplete="one-time-password"
                  inputMode="numeric" 
                />
              </div>
              {/* <Title
                style={{ margin: 0, textAlign: "center" }}
                level={4}
                underline
                strong
              >
                {localPin ? ('Please Enter Your Quick Access PIN') : ('Please Create PIN For Quick Access')}
              </Title> */}
            </div>
            {/* <Divider>OR</Divider> */}
            <Form.Item>
              <Button onClick={logOutUser}
               icon={<LogoutOutlined />}
                type="primary"
                danger
                htmlType="submit"
                size="small"
                // style={{ width:'30%'}}
                >
                 {t("LOG OUT")}
              </Button>
            </Form.Item>
          </div>
        </Content>
      </Layout>
    </>
  );
}

export default Pin;
