import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import dayjs from "dayjs";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tag, Spin, Tabs, Empty } from "antd";
import SignatureRecorder from "../../utility/SignatureRecorder";
import { useTranslation } from "react-i18next";
const { Meta } = Card;
const { TabPane } = Tabs;

const ClientDetails = () => {
  const {t} = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [client, setClient] = useState();
  const [medication, setMedication] = useState([]);
  const [serviceAgreement, setserviceAgreement] = useState([]);
  const [careplan, setcareplan] = useState([]);
  const [loading, setLoading] = useState(false);
  const [carePlanTabs, setCarePlanTabs] = useState([]);
  const [RpData, setRpData] = useState();
  const [cliEmergencyData, setCliEmergencyData] = useState();

  useEffect(() => {
    getClient();
  }, []);

  useEffect(() => {
    let UserInfo = JSON.parse(localStorage.getItem("USER_INFO")) || "";
    if (UserInfo) {
      getCarePlanTabs(UserInfo.companyId._id);
    }
  }, []);

  const getClient = () => {
    const local = "emp/detail/client";
    const params = { clientId: id };
    setLoading(true);
    AxiosInstance.get(local, { params })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (data.success) {
          setClient(data.data.client);
          setRpData(data.data.clientRp);
          setCliEmergencyData(data.data.clientEmergency);
          setMedication(data.data.medication);
          setserviceAgreement(data.data.service_agreement);
        } else {
          setClient(null);
          setRpData("");
          setCliEmergencyData("");
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
        // Handle error or display message
      });
  };

  const getCarePlanTabs = (id) => {
    const local = "emp/careplan-company";
    const params = { cid: id };
    setLoading(true);
    AxiosInstance.get(local, { params })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (data.success) {
          setCarePlanTabs(data.data.rows);
          getCareplanClient(data.data.rows[0].type);
        } else {
          setCarePlanTabs([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
      });
  };

  const getCareplanClient = (type = "") => {
    const local = "emp/careplan-client";
    const params = { client_id: id, type: type };
    setLoading(true);
    AxiosInstance.get(local, { params })
      .then((response) => {
        setLoading(false);
        const data = response.data;
        if (data.success) {
          if (type === "245D-Intensive") {
            setcareplan(data.data.outcome_section);
          } else {
            setcareplan(data.data.careplan);
          }
        } else {
          setcareplan([]);
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
        // Handle error or display message
      });
  };

  const handleTabClick = (key, tabType) => {
    getCareplanClient(tabType);
  };

  const backToHomePage = () => {
    history.push({
      pathname: "/Clients",
    });
  };

  return (
    <div>
      <Spin
        size="large"
        spinning={loading}
        fullscreen
        tip={t('Fetching Your Data')}
      />
      <Card style={{ margin: 10, marginBottom: '50px' }}>
        <Row
          gutter={16}
          style={{ marginTop: 10, marginBottom: 10, marginLeft: 10 }}
        >
          <Col span={12}>
            <ArrowLeftOutlined
              style={{
                marginRight: "10px",
                float: "left",
                fontSize: "1.3rem",
                color: "red",
                cursor: "pointer",
              }}
              onClick={backToHomePage}
            />
          </Col>
          {/* Render Client Details */}
          {client && (
            <Col span={24}>
              <h1>
                {client.fName} {client.lName}
              </h1>
              <p><span style={{ fontWeight: 'bolder' }}>{t('DOB')}:</span> {dayjs(client.dob).add(1, 'day').format("MM/DD/YYYY")}</p>
              <p><span style={{ fontWeight: 'bolder' }}>{t('Phone')}:</span> {client.phone1.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}</p>
              <p>
                {client.street} {client.zipcode} {client.city} {client.county}{" "}
                {client.state}
              </p>
              {/* Other client details */}

              <div>
                <Tag color="magenta">
                {t('SafetyMeasure')} :<span style={{ textWrap: 'pretty' }}>{client.safety_measure || "  N/A"}</span>
                </Tag>
                <Tag color="lime">
                {t('Equipment')}:
                  <span style={{ textWrap: 'pretty' }}>{client.special_equipment || "  N/A"}</span>
                </Tag>
                <Tag color="cyan">
                  {" "}
                  text={t('Allergies')}:<span style={{ textWrap: 'pretty' }}>{client.allergies || "   N/A"}</span>{" "}
                </Tag>
                <Tag color="purple">
                  {" "}
                  {t('OtherServices')}:<span style={{ textWrap: 'pretty' }}>{client.other_services || "  N/A"}</span>
                </Tag>
                {/* <Tag color="lime">
                   <SignatureRecorder />
              </Tag> */}
              </div>

              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', marginTop: "20px" }}>
      {RpData?.rPhone && (
        <div style={{ flex: 1, minWidth: '45%', margin: 10 }}>
          <Card style={{background: "#f0f2f5",}}
          >
            <Row gutter={[18, 18]} style={{ marginBottom: "10px", marginLeft: "10px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <h4 style={{textAlign:'center', margin:10}}>{t('RP Details')}</h4>
                <h6>
                  {RpData?.rName}
                </h6>
                <p><span style={{ fontWeight: 'bolder' }}>{t('Phone')}:</span> {RpData.rPhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}</p>
                <p>
                  {RpData.rAddr}
                </p>
              </Col>
            </Row>
          </Card>
        </div>
      )}

      {cliEmergencyData?.ePhone && (
        <div style={{ flex: 1, minWidth: '45%', margin: 10 }}>
          <Card style={{background: "#f0f2f5",}}
           
          >
            <Row gutter={[18, 18]} style={{ marginBottom: "10px", marginLeft: "10px" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <h4 style={{textAlign:'center', margin:10}}>{t('Emergency Details')}</h4>
                <h6>
                  {cliEmergencyData?.eName}
                </h6>
                
                <p><span style={{ fontWeight: 'bolder' }}>{t('Phone')}:</span> {cliEmergencyData.ePhone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}</p>
                <p><span style={{ fontWeight: 'bolder' }}>{t('Relation')}:</span> {cliEmergencyData.eRelation}</p>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </div>

              <div style={{ marginTop: "20px" }}>
                {serviceAgreement.length ? (
                  <Card
                    title={t('Services')}
                    style={{ margin: 10 }}
                    hoverable={true}
                  >
                    <Row gutter={[18, 18]} style={{ marginBottom: "10px" }}>
                      {serviceAgreement.map((item, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
                          <Card
                            style={{
                              margin: "10px",
                              background: "#f0f2f5",
                              position: "relative",
                              boxShadow: "none",
                            }}
                          >
                            <Meta
                              title={
                                <div
                                  style={{
                                    marginTop: "10px",
                                    padding: "0 14px",
                                    textWrap: "pretty",
                                  }}
                                >
                                  {dayjs(item.sDate).add(1, 'day').format("MM/DD/YYYY")} -{" "}
                                  {dayjs(item.eDate).add(1, 'day').format("MM/DD/YYYY")}{" "}
                                  <span
                                    style={{
                                      fontSize: "0.6rem",
                                      color: "#531dab",
                                    }}
                                  >
                                    ({item.sDes} - {item.serviceTitle}){" "}
                                  </span>
                                  {/* {item.outcome} */}
                                </div>
                              }
                              description={
                                <>
                                  <div
                                    style={{
                                      marginTop: 10,
                                      padding: "0 14px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <div>{t('TotalHrs')}</div>
                                      <div>{t('Used Hrs')}</div>
                                      <div>{t('Remaining')}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div>
                                        {Number.isInteger(item.tHours)
                                          ? item.tHours
                                          : item.tHours.toFixed(2)}
                                      </div>
                                      <div>
                                        {Number.isInteger(item.used)
                                          ? item.used
                                          : item.used.toFixed(2)}
                                      </div>
                                      <div>
                                        {Number.isInteger(item.rem)
                                          ? item.rem
                                          : item.rem.toFixed(2)}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              }
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                ) : null}
                {medication.length ? (
                  <Card
                    title={t('Medication')}
                    style={{ margin: 10 }}
                    hoverable={true}
                  >
                    <Row gutter={[18, 18]} style={{ marginBottom: "10px" }}>
                      {medication?.map((item, index) => (
                        <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
                          <Card
                            style={{
                              margin: "10px",
                              background: "#f0f2f5",
                              position: "relative",
                              boxShadow: "none",
                            }}
                          >
                            <Meta
                              title={
                                <div
                                  style={{
                                    marginTop: "10px",
                                    padding: "0 14px",
                                    textWrap: "pretty",
                                  }}
                                >
                                  {item.medication}{" "}
                                  <span
                                    style={{
                                      fontSize: "0.6rem",
                                      color: "#531dab",
                                    }}
                                  >
                                    ({item.dosage})
                                  </span>
                                </div>
                              }
                              description={
                                <>
                                  <div
                                    style={{
                                      marginTop: 10,
                                      padding: "0 14px",
                                      marginBottom: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <div>{t('Pharmacy')}</div>
                                      <div>{t('Route')}</div>
                                      <div>{t('Frequency')}</div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-around",
                                      }}
                                    >
                                      <div>{item.pharmacy}</div>
                                      <div
                                        style={{
                                          textWrap: "wrap",
                                          maxWidth: "74px",
                                        }}
                                      >
                                        {item.route}
                                      </div>
                                      <div>{item.frequency}</div>
                                    </div>
                                  </div>
                                </>
                              }
                            />
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  </Card>
                ) : null}

                <Card
                  title={t('CarePlans')}
                  style={{ margin: 10 }}
                  hoverable={true}
                >
                  <div>
                    <Tabs
                      defaultActiveKey="0"
                      onChange={(key) =>
                        handleTabClick(key, carePlanTabs[key]?.type)
                      }
                      centered
                      size="large"
                      type="card"
                    >
                      {carePlanTabs.map((tab, index) => (
                        <TabPane tab={tab.type} key={index.toString()}>
                          {tab.type === "PCA" || tab.type === "245D" ? (
                            <Row
                              gutter={[16, 16]}
                              className="mt-3"
                              style={{ margin: "0px 0px" }}
                            >
                              {careplan.map((item, index) => (
                                <Col
                                  key={index}
                                  xs={24}
                                  sm={24}
                                  md={12}
                                  lg={6}
                                  xl={8}
                                >
                                  <Card
                                    style={{
                                      background: "#f0f2f5",
                                      borderLeftWidth: "3px",
                                      fontWeight: "bold",
                                      position: "relative",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <Meta
                                      title={
                                        <div style={{}}>
                                          <div
                                            style={{
                                              marginTop: 5,
                                              marginBottom: 5,
                                              marginLeft: "5px",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                              }}
                                            >
                                              <span> {item.code}</span>
                                              <span
                                                style={{
                                                  fontSize: "0.7rem",
                                                  color: "#8546A7",
                                                  textWrap: 'pretty'
                                                }}
                                              >
                                                {" "}
                                                {item.instruction || item?.task}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      }
                                    />
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          ) : (
                            <Row
                              gutter={[16, 16]}
                              className="mt-3"
                              style={{ margin: "0px 0px", paddingBottom: "20px" }}
                            >
                              {careplan.map((item, index) => (
                                <Col
                                  key={index}
                                  xs={24}
                                  sm={24}
                                  md={12}
                                  lg={6}
                                  xl={8}
                                >
                                  <Card
                                    style={{
                                      background: "#f0f2f5",
                                      borderLeftWidth: "3px",
                                      fontWeight: "bold",
                                      position: "relative",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <Meta
                                      title={
                                        <>
                                          <div style={{ padding: "5px 6px", textAlign: 'center' }}>
                                            <span style={{ fontSize: '1rem', }}>{item.outcome}</span>
                                          </div>
                                          <div
                                            style={{
                                              display: "flex",
                                              flexDirection: "row",
                                              justifyContent: "space-between",
                                              fontSize: "1rem",
                                              color: "#8546A7",
                                              padding: "10px 10px",
                                            }}
                                          >
                                            <span style={{
                                              fontSize: "0.7rem",
                                              color: "#8546A7",
                                              textWrap: 'pretty'
                                            }}>
                                              {/* <span style={{ fontWeight: 'bold', fontSize: '13px', textDecoration: 'underline', color: 'black' }}>Instruction:</span>
                                               {item.instruction} */}
                                               </span>
                                            <span style={{ fontSize: "0.7rem", }}><span style={{ fontWeight: 'bold', fontSize: '13px', textDecoration: 'underline', color: 'black' }}>Qualifier:</span> {item.qualifier}</span>
                                          </div>
                                        </>
                                      }
                                    />
                                  </Card>
                                </Col>
                              ))}
                            </Row>
                          )}
                          {careplan.length === 0 && (
                            <Empty description={t('NoDataFound')} />
                          )}
                        </TabPane>
                      ))}
                    </Tabs>
                  </div>
                </Card>
              </div>
            </Col>
          )}
        </Row>
      </Card>
    </div>
  );
};

export default ClientDetails;
