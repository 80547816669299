import React, { useState, useEffect } from "react";
import AxiosInstance from "../../services/axiosInstance";
import moment from "moment";
import dayjs from "dayjs";
import {
  Form,
  Input,
  Button,
  DatePicker,
  Row,
  Col,
  Card,
  Spin,
  Divider,
  Badge
} from "antd";
import { useTranslation } from "react-i18next";
import { message as messageApi } from "antd";
const Hospitalization = () => {
  const { t } = useTranslation();
  const { Meta } = Card;
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hostpzList, setHostpzList] = useState([]);
  const [warningLoading, setWarningLoading] = useState(false);
  const today = moment(new Date()).format("DD/MM/YYYY");
  const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
  const disableDate = userInfo?.companyId?.state === "IL" ? true : false;
  const Formfield = [
    {
      name: "sDate",
      label: t('Start Date'),
      type: "date",
      value: "",
      placeholder: t('Start Date'),
      required: true,
    },
    {
      name: "eDate",
      label: t('End Date'),
      type: "date",
      value: "",
      placeholder: t('End Date'),
      required: true,
    },
    {
      name: "name",
      label: t('Hospital Name'),
      type: "TextArea",
      placeholder: t('Hospital Name'),
      required: true,
    },
    {
      name: "empNote",
      label: t('Note'),
      type: "TextArea",
      placeholder: t('Enter Note'),
      required: true,
    },
  ];

  useEffect(() => {
    form.resetFields();

    form.setFieldsValue({
      sDate: dayjs(today, "DD/MM/YYYY"),
      eDate: dayjs(today, "DD/MM/YYYY"),
    });
    getHospitalization();
  }, []);

  const renderFormField = (field) => {
    const {
      type,
      name,
      label,
      TextArea,
      placeholder,
      min_date,
      max_date,
    } = field;
    switch (type) {
      case "date":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[{ required: field.required, message: `Please  ${label}` }]}
          >
            <DatePicker
              placeholder={placeholder}
              format={"MM/DD/YYYY"}
              disabledDate={disableDate}
              size="large"
            />
          </Form.Item>
        );

      case "TextArea":
        return (
          <Form.Item
            key={name}
            label={label}
            name={name}
            rules={[
              { required: field.required, message: `Please enter ${label}` },
            ]}
          >
            <Input.TextArea type={TextArea} size="large" autoSize placeholder={placeholder} />
          </Form.Item>
        );
      default:
        return null;
    }
  };

  const handleFormChange = (allValues) => {
    if (allValues.sDate && allValues.sDate.$d)
      allValues.sDate = dayjs(allValues.sDate.$d).format("YYYY-MM-DD");
  };

  const onFinish = (values) => {
    if (values.sDate > values.eDate) {
      messageApi.error(t('Start Date must be less than or equal to End Date'));
      return;
    }
    setLoading(true);
    const obj = {
      sDate: dayjs(values.sDate.$d).format("YYYY-MM-DD"),
      eDate: dayjs(values.eDate.$d).format("YYYY-MM-DD"),
      reason: values.empNote,
      name: values.name,
      client_id: userInfo?._id,
      companyId: userInfo?.companyId?._id,
    };
    const url = "client/hospitalization";
    AxiosInstance.post(url, obj)
      .then((res) => {
        setLoading(false);
        if (res.data.success) {
          messageApi.success(res.data.message);
          form.resetFields();
          form.setFieldsValue({
            sDate: dayjs(today, "DD/MM/YYYY"),
            eDate: dayjs(today, "DD/MM/YYYY"),
          });
          getHospitalization();
        } else {
          messageApi.error(res.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error fetching data:", error);
      });
  };
  const getHospitalization = () => {
    setWarningLoading(true);
    let endpoint = "client/hospitalization?client_id=" + userInfo?._id;
    AxiosInstance.get(endpoint)
      .then(res => {
        setWarningLoading(false);
        if (res.data.success) {
          setHostpzList(res.data.data.reverse());
        } else {
          setHostpzList([])
          messageApi.error(res.data.message);
        }

      })
  }
  return (
    <div>
      <Form
        className='hospitalization-info'
        onValuesChange={handleFormChange}
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ padding: "0px 100px" }}
      >
        <Row gutter={[16, 0]}>
          {Formfield.map((field, index) => (
            <Col key={index} xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}>
              {renderFormField(field)}
            </Col>
          ))}
        </Row>
        <Form.Item style={{ textAlign: 'end' }}>
          <Button size={'large'} className="ant-btn-form" type="primary" htmlType="submit" loading={loading}>
            {t('Submit')}
          </Button>
        </Form.Item>
      </Form>
      <Divider></Divider>
      <Spin spinning={warningLoading} fullscreen size='large' />
      <Row
        gutter={[16, 16]}
        className="mt-3"
        style={{ margin: "10px 0px 100px" }}
      >
        {hostpzList.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={16} xl={6}>
            <Card
              style={{
                background: "#f0f2f5",
                borderLeftWidth: "3px",
                // borderLeftColor: getColor(item),
                fontWeight: "bold",
                position: "relative",
                boxShadow: "none",
              }}
            >
              <Meta
                title={
                  <div style={{ paddingLeft: 10, paddingTop: 10 }}>
                    <div style={{ marginLeft: "5px" }}>
                      <div>
                        <span>
                          {" "}
                          {dayjs(item?.s_date).add(1, 'day').format('MM/DD/YYYY')} - {dayjs(item?.e_date).add(1, 'day').format('MM/DD/YYYY')}
                        </span>
                      </div>
                    </div>
                  </div>
                }
                description={
                  <div style={{ paddingBottom: 10, }}>
                    <div
                      style={{

                        marginLeft: "15px",
                        whiteSpace: "normal",
                        maxWidth: "100%", // Ensure the container can adjust to its parent's width
                      }}
                    >
                      {t('Hospital Name')}: {item.name}
                    </div>
                    <div
                      style={{
                        // marginBottom: "5px",
                        marginLeft: "15px",
                        // marginTop: '-9px',
                        wordWrap: "break-word",
                        whiteSpace: "normal",
                        maxWidth: "100%", // Ensure the container can adjust to its parent's width
                      }}
                    >
                      {t('Note')}: {item.reason}
                    </div>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Hospitalization