import React, { useEffect, useState } from 'react';
import SignatureRecorder from './../../utility/SignatureRecorder';
import { AudioTwoTone   } from '@ant-design/icons';
import { Modal, Button} from 'antd';
import { useTranslation } from 'react-i18next';
const ChatFooter = ({ socket,selectedUser }) => {
  const {t} = useTranslation();
  const conversation_id = selectedUser.split('--')[0];
  const receiverId = selectedUser.split('--')[1];
  const receiverName = selectedUser.split('--')[2];
  const conversationUserType = selectedUser.split('--')[3];
  const userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
  const [message, setMessage] = useState([]);
  const [showDigitalSign, setShowDigitalSign] = useState(false);
  const [base64Audio, setBase64Audio] = useState(null);
  // const [selectedUser, setSelectedUser] = useState(user);

  useEffect(() => {
    console.log(selectedUser,"selectedUser")
  socket.on('typing', (res) => {
    if (res.msg) {
      // this.typing = res.msg;
      setTimeout(() => {
        // this.typing = "";
      }, 2000);
    }
  });
},[]);

const onTyping = () => {
    if (receiverId) {
      let obj = {
        "receiver_id": receiverId
      }
      socket.emit('typing', obj);
    }
}
const setInputData = (e) =>{
  onTyping();
   setMessage(e.target.value)
}
  const handleSendMessage = (e,fileType="",file="") => {
   if(e) e.preventDefault();
    if (message || file) {
      const data = {
        "conversation_id":conversation_id,
        "text":message,
        "sender":`${userId.fName} ${userId.lName}`,
        "receiver_id": receiverId,
        "target": conversationUserType,
        "type":fileType || 'text',
       "file": file
      }
      console.log(data, "values")
      socket.emit('message', data);
    }
    console.log({ userName: localStorage.getItem('userName'), message });
    setMessage('');
  };

  const digitalRecording = () =>{
    setShowDigitalSign(true);    
}
const isRecorded = (isStarted) => {
  console.log("reCorded Stared", isStarted);
  // setDisabled(isStarted);
}
const onSelectVoice = (audio) => {
  if(audio){  
    const string =  `data:audio/webm;base64,${audio}`;
    handleSendMessage('',"audio",string);
    setShowDigitalSign(false)
  }
};
const closeSignatureModal = () => {
  setShowDigitalSign(false);
}
  return (
    <>
    <div className="chat__footer">
      <form className="form" onSubmit={handleSendMessage}>
        <input
          type="text"
          placeholder={t('Write message')}
          className="message"
          value={message}
          onChange={(e) => setInputData(e)}
        />
          <span onClick={digitalRecording} style={{ fontSize: "1.3rem", cursor:'pointer' }}>  <AudioTwoTone   /> </span>
         {/* <button type="button" className="audioBtn" onClick={digitalRecording}>
           Audio icon
        </button> */}
        
        <button className="sendBtn">{t('Send')}</button>
      </form>
    </div>

    <Modal
          label={t('Send')}
          open={showDigitalSign}
          onCancel={closeSignatureModal}
          closable={true}
          footer={null}
          maskClosable={false}
        >
        <div>
          <SignatureRecorder  onVoice={onSelectVoice}
            isRecordStared={isRecorded} type="" />
        </div>

        <div>
      {/* <h3>Audio Sign</h3> */}
      {/* <div style={{display:'flex',gap:'9px'}}>
      {base64Audio?.signature && (
        <audio controls id="audioElement"  style={{ width: "90%", height: "40px" }}>
          <source src={`data:audio/webm;base64,${base64Audio.signature}`} type="audio/webm" />
          <source src={`data:audio/wav;base64,${base64Audio.signature}`} type="audio/wav" />
          <source src={`data:audio/ogg;base64,${base64Audio.signature}`} type="audio/ogg" />
          <source src={`data:audio/mpeg;base64,${base64Audio.signature}`} type="audio/mpeg" />
          <FormattedMessage id="Yourbrowserdoesnotsupporttheaudioelement" />
        </audio>
      )}
      {base64Audio?._id && (
        <DeleteOutlined  style={{ fontSize: '35px', color: '#ff4d4f' }}/>
        //onClick={() => deleteRecording(base64Audio._id)} <button >Delete</button>
      )}</div> */}
    </div>
        </Modal>
    </>
  );
};

export default ChatFooter;