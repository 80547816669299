import React from "react";
import { Button, Result } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PageNotFound = () => {
  const {t} = useTranslation();
  const history = useHistory();
 const backToDashboard = () =>{
    history.push("/home");
  }
  return (
    <Result
      status="404"
      title="404"
      subTitle={t('Page Not Found')}
      extra={<Button type="primary" onClick={backToDashboard}>{t('Back Home')}</Button>}
    />
  );
};
export default PageNotFound;
