import { PhoneTwoTone, EnvironmentTwoTone, MailTwoTone } from '@ant-design/icons';
import React, { useState,useEffect } from 'react';
import { Avatar, Card, Spin } from 'antd';
import image from "../../assets/images/dummyimage.png";
import AxiosInstance from "../../services/axiosInstance";

const ContactUs = () => {
    const { Meta } = Card;
    const [warningLoading, setWarningLoading] = useState(false);
    const [contactDetails, setContactDetails] = useState(null);

    useEffect(() => {
        getContactInfo()
    }, []);


    const getContactInfo = () => {
        setWarningLoading(true);
        let endpoint = "client/company-contact";
        AxiosInstance.get(endpoint, '')
            .then(res => {
                setWarningLoading(false);
                if (res.data.success) {
                    setContactDetails(res.data.data);
                } else {
                    setContactDetails("")
                }

            })
            .catch(error => {
                setWarningLoading(false);
            });
    }

  return (
    <>
     <Spin spinning={warningLoading} fullscreen tip="Fetching your data, Please bear with us a moment!🕒" size='large' />
       <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Card
        style={{
            // borderWidth: "3px",
            // borderColor:"rgb(145, 202, 255)",
          width: 800,
        //   height:600,
          marginTop: 16,
        }}
        actions={[
        <span style={{fontWeight:'bold'}}><PhoneTwoTone />{"   "} {contactDetails?.phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1)$2-$3')}</span>,
        <span style={{fontWeight:'bold'}}><MailTwoTone />{"   "} {contactDetails?.email ? contactDetails?.email : "N/A"}</span>,
        ]}
      >
        <Meta
          avatar={<Avatar style={{marginTop:'10px' ,width:'120px' ,height:'120px', marginLeft:'10px'}}   src={contactDetails?.logo ? contactDetails?.logo: image}/>}
          title={
            <div style={{marginTop:'30px'}}>
            <span>{contactDetails?.name}</span>
            <span>{"  "}({contactDetails?.sName})</span>
            </div>
            
        }
          description={
            <div style={{marginBottom:'10px', fontWeight:'bold'}}>
     <EnvironmentTwoTone  />{"  "}   {contactDetails?.street}   {contactDetails?.city}   {contactDetails?.county}   {contactDetails?.state}   {contactDetails?.zip} 
            </div>
          }
        />
      </Card>
    </div>
    </>
  );
};
export default ContactUs;