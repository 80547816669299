import { useState, useEffect,useRef  } from "react";
import { Spin, Card, Row, Col, Modal } from 'antd';
import AxiosInstance from "../../services/axiosInstance";
import logo1 from "../../assets/images/logo.png";
const Help = () => {
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef(null);
  useEffect(() => {
    getVideos();
  }, []);

  const getVideos = () => {
    setLoading(true);
    let endpoint = "video-url";
    AxiosInstance.get(endpoint).then((res) => {
      setLoading(false);
      if (res.data.success) {
        setVideos(res.data.data);
      } else {
        setVideos([]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (selectedVideo && videoRef.current) {
      videoRef.current.play();
    }
  }, [selectedVideo]);
  const handleVideoClick = (video) => {
    setSelectedVideo(video);
  };

  const closeModal = () => {
    setSelectedVideo(null);
  };

  return (
    <>
       <Spin
        spinning={loading}
        fullscreen
        size="large"
      />
      {videos.length ? (
        <Row gutter={[16, 16]}>
          {videos.map((video) => (
            <Col key={video._id} xs={24} sm={12} md={8} lg={6} xl={6}>
              <Card style={{height:'350px'}}
                hoverable
                cover={
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' , marginTop:10}}>
                     <video onClick={() => handleVideoClick(video)}   style={{ cursor: 'pointer', width: '30%' }}>
            <source src={video.url} type="video/mp4" />
            
          </video>
                  {/* <img
                    alt={video.name}
                    src={video.url} // Make sure `logo1` is properly imported
                    onClick={() => handleVideoClick(video)}
                    style={{ cursor: 'pointer', width: '30%' }}
                  /> */}
                </div>
                }
              >
                 <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', marginTop:20 , marginBottom:10}}>
                 <Card.Meta title={video.name}/>
                 </div>
                
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <>
          {/* <Skeleton.Input active={true} size="default" />
          <Skeleton.Image active={true} style={{ height: '20px', marginTop: '10px' }} /> */}
        </>
      )}

      {selectedVideo && (
        <Modal
          visible={!!selectedVideo}
          title={selectedVideo.name}
          onCancel={closeModal}
          footer={null}
          width={400}  // Set the width of the modal to a smaller size
        >
          <video controls style={{ width: '100%' }} ref={videoRef}>
            <source src={selectedVideo.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <p>{selectedVideo.description}</p>
        </Modal>
      )}
    </>
  );
};

export default Help;
