import React, { useEffect, useState } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { RefreshComponet } from "../../utility/UserDetails";
import {
  Row,
  Col,
  Button,
  Select,
  Input,
  Tooltip,
  DatePicker,
  Badge,
  Tag,
  Card,
  Spin,
  Checkbox,
  Modal,
  Alert,
  Empty,
} from "antd";
import { message as messageApi } from "antd";
import { SnippetsFilled, CheckOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import ReactCardFlip from "react-card-flip";
import { useTranslation } from "react-i18next";
function Emar() {
  const uType = localStorage.getItem("type");
  const {t} = useTranslation();
  const { TextArea } = Input;
  const { Meta } = Card;
  const { Option } = Select;
  const [warningLoading, setWarningLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [SelectedNote, setSelectedNote] = useState({});
  const [medicationList, setMedicationList] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectAllActive, setSelectAllActive] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [schData, setSchData] = useState({
    clientId: "",
    date: dayjs(),
  });
  const [med_notes, setMedNotes] = useState("");
  const [med_initials, setMedInitials] = useState("");

  useEffect(() => {
    setWarningLoading(true);
    if (uType === "c") {
      let userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
      if (userId._id) schData.clientId = userId._id;
    } else {
      const url = `emp/my-client`;
      AxiosInstance.get(url)
        .then((res) => {
          setWarningLoading(false);
          const clients = res.data.data;
          setClientList(clients);
          if (clients.length == 1) {
            setSchData((prevState) => ({
              ...prevState,
              clientId: clients[0]._id,
            }));
          }
        })
        .catch((error) => {});
    }
    if (schData.clientId && schData.date) getEmarList();
  }, [schData.clientId, schData.date]);

  // useEffect(() => {
  //     getEmarList();

  // }, [schData.date, schData.clientId]);

  const getEmarList = () => {
    let StartDate = dayjs(schData.date).format("YYYY-MM-DD");
    // if(uType === 'e'){
    if (!schData.clientId) {
      return;
    }
    // }

    setWarningLoading(true);
    let endpoint =
      "client/medicine-routine?clientid=" +
      schData.clientId +
      "&c_date=" +
      StartDate;
    AxiosInstance.get(endpoint)
      .then((res) => {
        setWarningLoading(false);
        if (res.data.dirtyBit) RefreshComponet(res.data.dirtyBit);
        if (res.data.success) {
          // Toaster("success", "Success", res.message);
          setMedicationList(res.data.data);
          setSelectedIds([])
        } else {
          setMedicationList([]);
          // Toaster("error", "Error", res.data.message);
        }
      })
      .catch((error) => {
        setWarningLoading(false);
      });
  };

  //onflip functionality
  const [flippedCard, setFlippedCard] = useState(null);

  const onFlipCard = (index, item) => {
    console.log(index,item)
    if (selectedIds.length === 0) {
      messageApi.warning('Please select a checkbox before add a note.');
      return;
    }
    setFlippedCard(index === flippedCard ? null : index);
  };
  const onFlipBackCard = () => {
    // Handle cancel action here, flip the card back
    setFlippedCard(null);
    setSelectedIds([]);
    // SetFlipType('');
    // setSelectedTime(dayjs());
    // setSelectedTime(moment());
  };

  const notesAlertPrompt = (item) => {
    setSelectedNote(item);
    setIsModalOpen(true);
  };

  const handleChange = (value, field) => {
    if(!value) return;
    setSchData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleChangeNotes = (value, type) => {
    if (type === "med_notes") {
      setMedNotes(value);
    } else if (type === "med_initials") {
      setMedInitials(value);
    }
  };
  const handleSelectAll = () => {
    const allIds = medicationList.map((item) => item._id);
    setSelectedIds(allIds); // Update selectedIds with all IDs from data
    setSelectAllActive(false); // Hide "Select All" button
  };
  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectAllActive(true); // Hide "Deselect All" button
  };

  const handleOk = () => {
    
    setWarningLoading(true);
    let obj = {
      // ids: medicationList.length ? medicationList.filter(res => res._id).map(res => res._id) : [],
      ids: selectedIds,
      med_initials: med_initials,
      med_notes: med_notes,
      client_id: schData.clientId,
      c_date:
        dayjs(schData.date).format("YYYY-MM-DD") ||
        dayjs().format("YYYY-MM-DD"),
    };
    let endpoint = "client/multi-medicine";
    AxiosInstance.put(endpoint, obj)
      .then(({ data }) => {
        if (data.success) {
          messageApi.success(data.message);
          setIsModalOpen(false);
          setFlippedCard(null);
          setMedNotes("");
          setMedInitials("");
          setSelectedIds([]);
          setSelectAllActive(true);
          getEmarList();
        } else {
          Toaster("info", "ATTENTION", data.message);
          setMedNotes("");
          setMedInitials("");
        }
      })
      .catch((err) => {
        console.log(err);
        // Toaster("error", "Error", err.message);
      })
      .finally(() => {
        // Reset loading to false when the operation is complete
        setWarningLoading(false);
      });
  };

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      const updatedSelectedIds = prevSelectedIds.includes(id)
        ? prevSelectedIds.filter((selectedId) => selectedId !== id)
        : [...prevSelectedIds, id];
        
      setSelectAllActive(updatedSelectedIds.length !== medicationList.length);
      
      return updatedSelectedIds;
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <div>
      {medicationList.length !== 0 &&
        <Alert
          message={
            <div style={{ float: 'inline-end' }}>

              {medicationList.length ? (
                <> 
                {selectedIds.length>0 && (
                   <Button type="primary" className='ant-btn-form'  onClick={notesAlertPrompt} style={{marginRight:'8px'}}>
                      {t('AddNote')}
                    </Button>)}
                  {selectAllActive ? (
                    <Button type="primary" className='ant-btn-form'  onClick={handleSelectAll}>
                     {t('SelectAll')}
                    </Button>
                  ) : (
                    <Button type="primary" danger className='ant-btn-form' onClick={handleDeselectAll} >
                     {t('UnselectAll')}
                    </Button>
                  )}
                    
                </>
              ) : null}
            </div>
          }
          type="info"
        />
      }
      <Row
        gutter={[8, 0]}
        style={{
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 10,
          fontWeight: "bold",
        }}
      >
        <Col
          span={6}
          xs={24}
          sm={24}
          md={12}
          lg={6}
          xl={uType === "e" ? 12 : 24}
        >
          <div>
            <label>
              {" "}
              {t('SelectDate')}
            </label>
          </div>
          <DatePicker
            defaultValue={dayjs()}
            format={"MM/DD/YYYY"}
            style={{ marginLeft: "-10px" }}
            onChange={(date) => handleChange(date, "date")}
            placeholder={t('SelectDate')}
            // disabledDate={
            //   uType === "e"
            //     ? (current) => current && current < dayjs().startOf("day")
            //     : undefined
            // }
          />
        </Col>
        {uType === "e" && (
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={12}>
            <div>
              <label>
                {t('SelectClient')}
              </label>
            </div>
            <Select
              style={{ width: "100%", marginLeft: "-10px" }}
              showSearch
              value={schData.clientId ? schData.clientId : (clientList.length === 1 ? clientList[0]._id : t('SelectClient'))}
              // value={
              //   clientList.length > 0 ? clientList[0]._id : "Select Client"
              // }
              onChange={(value) => handleChange(value, "clientId")}
            >
              {clientList.map((option) => (
                <Option key={option._id} value={option._id}>
                  {option.fName} {option.lName}
                </Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>

      <Spin
        spinning={warningLoading}
        fullscreen
        tip={t('Fetching Your Data')}
        size="large"
      />
      <Row gutter={[16, 16]} className="mt-3" style={{ margin: "0px 0px" }}>
        {medicationList.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={6} xl={8}>
            <ReactCardFlip
              className="characterCard"
              isFlipped={index === flippedCard}
              flipDirection="horizontal"
            >
              <Badge.Ribbon
                placement="start"
                text={item.med_time}
                color={"cyan"}
              >
                <Card
                  className="ant-tabs-tabpane"
                  style={{
                    background: "#f0f2f5",
                    borderLeftWidth: "3px",
                    borderLeftColor: "cyan",
                  }}
                >
                  <Meta
                    description={
                      <div style={{}}>
                        <div
                          style={{
                            position: "absolute",
                            right: "4px",
                            top: "0.5rem",
                          }}
                          >
                          {/* {!item.med_initials && !item.med_notes ? ( */}
                            {/* <Tooltip placement="bottom" title={"Add Note"} style={{marginLeft:'20px'}}>
                              <Tag
                                style={{
                                  float: "inline-end",
                                  cursor: "pointer",
                                }}
                                color="processing"
                                icon={
                                  <SnippetsFilled
                                    onClick={() => onFlipCard(index,item)}
                                  />
                                }
                                onClick={() => notesAlertPrompt(item)}
                              ></Tag>
                                
                            </Tooltip> */}
                            <span style={{marginRight:'8px'}}>
                            <Tag
                                style={{
                                  float: "inline-end",
                                  cursor: "pointer",
                                }}
                                
                                color="processing"
                              >
                                 <Checkbox 
                          checked={selectedIds.includes(item._id)}
                          onChange={() => handleCheckboxChange(item._id)}
                          ></Checkbox>
                              </Tag>
                           </span>
                          {/* ) : (
                            <Tag
                              style={{ float: "inline-end" }}
                              color="success"
                              icon={<CheckOutlined />}
                            ></Tag>
                          )} */}
                        </div>
                        <div
                          style={{
                            marginTop: 33,
                            marginLeft: "5px",
                            marginBottom: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            {dayjs(item?.med_date).add(1, 'day').format('MM/DD/YYYY')}
                            <span
                              style={{ fontSize: "0.7rem", color: "#8546A7" }}
                            >
                              {" "}
                              {item?.med_id.dosage || "N/A"}
                            </span>
                          </div>
                          <div>
                            {" "}{t('Medication')}:
                            <span>
                              {} {item.med_id.medication || "N/A"}
                            </span>
                          </div>
                          <div>
                            {" "}{t('Route')}:
                            <span>
                              {} {item.med_id.route || "N/A"}
                            </span>
                          </div>
                          {item.med_id.frequency !== "Nill" && (
                            <div>
                              {" "}{t('Frequency')}:
                              <span>
                                {} {item.med_id.frequency || "N/A"}
                              </span>
                            </div>
                          )}

                          {item.med_notes && (
                            <div>
                              {" "}{t('Notes')}:
                              <span>
                                {} {item.med_notes}
                              </span>
                            </div>
                          )}

                          {item.med_initials && (
                            <div>
                              {" "}{t('Initials')}:
                              <span>
                                {} {item.med_initials}
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </Card>
              </Badge.Ribbon>

              <Card
                style={{
                  background: "#f0f2f5",
                  // borderColor: getColor(c),
                  borderLeftWidth: "3px",
                }}
              >
                <Col xs={24}>
                  <Meta
                    style={{ marginTop: 20 }}
                    title={t('AddNote')}
                    description={
                      <>
                        <Row
                          gutter={[16, 0]}
                          style={{
                            marginTop: 10,
                            marginBottom: 10,
                            marginLeft: 10,
                            fontWeight: "bold",
                          }}
                        >
                          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={24}>
                            <div>
                              <label>
                                {" "}{t('Enter Medication Note')}
                              </label>
                            </div>
                            <TextArea
                              placeholder={t('Enter Medication Note')}
                              maxLength={100}
                              autoSize
                              value={med_notes}
                              onChange={(e) =>
                                handleChangeNotes(e.target.value, "med_notes")
                              }
                            />
                          </Col>
                          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={24}>
                            <div>
                              <label>
                                {" "}
                                {t('Enter Medication Initials')}
                              </label>
                            </div>
                            <TextArea
                              placeholder={t('Enter Medication Initials')}
                              maxLength={100}
                              autoSize
                              value={med_initials}
                              onChange={(e) =>
                                handleChangeNotes(
                                  e.target.value,
                                  "med_initials"
                                )
                              }
                            />
                            <div
                              style={{
                                float: "inline-end",
                                padding: "25px 0px",
                              }}
                            >
                              <Button
                                onClick={onFlipBackCard}
                                type="primary"
                                className="ant-btn-form"
                                danger
                              >
                                {t('Cancel')}{" "}
                              </Button>
                              <Button
                                type="primary"
                                className="ant-btn-form"
                                disabled={!med_notes && !med_initials}
                                style={{ marginLeft: "8px" }}
                                onClick={() => handleOk()}
                              >
                               {t('Yes')}{" "}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </>
                    }
                  />
                </Col>
              </Card>
            </ReactCardFlip>
          </Col>
        ))}
      </Row>
      <Row style={{marginTop:'10rem',flexDirection:'row',justifyContent:'center'}}>
      {(medicationList.length === 0) && (
              <Empty description={t('NoDataFound')} style={{textAlign:'center'}} />
            )}
      </Row>

      <Modal
        maskClosable={false}
        title={t('AddNotes')}
        open={isModalOpen}
        loading={warningLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            className="ant-btn-modal"
            key="save"
            onClick={handleOk}
            
            disabled={!med_notes || !med_initials}
          >
            {t('Save')}
          </Button>,
        ]}
      >
        <Row
          gutter={[16, 0]}
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 10,
            fontWeight: "bold",
          }}
        >
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={24}>
            <div>
              <label>
                {" "}
              {t('Enter Medication Note')}
              </label>
            </div>
            <TextArea
              placeholder={t('Enter Medication Note')}
              maxLength={100}
              autoSize
              value={med_notes}
              onChange={(e) => handleChangeNotes(e.target.value, "med_notes")}
            />
          </Col>
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={24}>
            <div>
              <label>
                {" "}{t('Enter Medication Initials')}
              </label>
            </div>
            <TextArea
              placeholder={t('Enter Medication Initials')}
              maxLength={100}
              autoSize
              value={med_initials}
              onChange={(e) =>
                handleChangeNotes(e.target.value, "med_initials")
              }
            />
          </Col>
        </Row>
      </Modal>
    </div>
  );
}

export default Emar;
