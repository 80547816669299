import React, { useEffect, useState } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { RefreshComponet } from "../../utility/UserDetails";
import { Card, Tabs, Col, Row, Spin, Alert, Select, Empty, Badge } from "antd";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const { Meta } = Card;
const { TabPane } = Tabs;
const Option = Select.Option;
const Earning = () => {
  const { t } = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("USER_INFO"));
  const companyId = userInfo?.companyId?._id;
  const [activeTab, setActiveTab] = useState("1");
  const [payrollPreLists, setPayrollPreLists] = useState([]);
  const [billigDatesAr, setBillingDatesAr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedBillingDates, setSelectedBillingDates] = useState({
    sDate: "",
    eDate: "",
  });

  useEffect(() => {
    getBillingCut();
  }, [activeTab]);


  const getBillingCut = () => {
    const local = `company/billing-cut?companyId=${companyId}`;
    setPayrollPreLists([]);
    setSelectedBillingDates("");
    setLoading(true);
    AxiosInstance.get(local)
      .then((response) => {
        setLoading(false);
        if (response.data.dirtyBit) RefreshComponet(response.data.dirtyBit)
        const data = response.data;
        if (data.success) {
          const dates = data?.data?.dates || [];
          // const dates = data?.data[0]?.dates || [];
          setBillingDatesAr(dates);
          dates.some((date) => {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);
            const startDate = new Date(date.s_date);
            startDate.setHours(0, 0, 0, 0);
            const endDate = new Date(date.e_date);
            endDate.setHours(0, 0, 0, 0);
            if (startDate <= currentDate && currentDate <= endDate) {
              if (activeTab === "2") {
                setPayrollPreLists([])
              } else {
                getPrevEarnings(
                  dayjs(date?.s_date).add(1, "day").format("YYYY-MM-DD"),
                  date.e_date
                );
              }
              return true; // Exit loop after the first match
            }
            return false;
          });
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const getPrevEarnings = (sDate, eDate) => {
    const local = `emp/monthly-payroll?sDate=${dayjs(sDate).format("YYYY-MM-DD")}&eDate=${dayjs(eDate).format("YYYY-MM-DD")}&companyId=${companyId}`;
    setLoading(true);
    AxiosInstance.get(local)
      .then((response) => {
        setLoading(false);
        if (response.data.dirtyBit) RefreshComponet(response.data.dirtyBit)
        const data = response.data;
        if (data.success) {
          setPayrollPreLists(data.data);
        } else {
          setPayrollPreLists([]);
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        // Alert.alert("Error", error.message);
        // Handle error or display message
      });
  };
  const selectDates = (id) => {
    const option = billigDatesAr.filter(x => x._id == id)[0]
    setSelectedBillingDates({
      sDate: dayjs(option?.s_date).add(1, "day").format("MM/DD/YYYY"),
      eDate: dayjs(option.e_date).format("MM/DD/YYYY"),
    });
    getPrevEarnings(
      dayjs(option?.s_date).add(1, "day").format("YYYY-MM-DD"),
      option.e_date
    );
  };
  const handleTabClick = (key) => {
    setActiveTab(key);
    setPayrollPreLists([]);
  };
  return (
    <>
      <Spin
        spinning={loading}
        size="large"
        fullscreen
      />
      <Tabs activeKey={activeTab} onChange={handleTabClick} type="card" left size="large">
        <TabPane tab={t('CurrentPayroll')} key="1">
          <Row gutter={[16, 16]}>
            {payrollPreLists.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={8}>
                <CurrentPreviousCard
                  item={item}
                  type="Current"
                />
              </Col>
            ))}
          </Row>
          {payrollPreLists.length === 0 && !loading && <Empty />}
        </TabPane>
        <TabPane tab={t('PreviousPayroll')} key="2">
          <Row
            gutter={[8, 0]}
            style={{
              marginTop: 10,
              marginBottom: 10,
              marginLeft: 5,
              marginRight: 5,
            }}
          >
            <Col
              span={6}
              xs={24}
              sm={24}
              md={12}
              lg={6}
              xl={12}
            >
              <Select
                size="large"
                placeholder={t('Select Dates')}
                optionFilterProp="children"
                onChange={selectDates}
                value={selectedBillingDates !== "" ? `${selectedBillingDates.sDate} - ${selectedBillingDates.eDate}` : undefined}
                style={{ borderColor: '#91caff' }}
              >
                {billigDatesAr.map((option, index) => (
                  <Option key={index} value={option._id}>
                    {dayjs(option.s_date).add(1, "day").format("MM/DD/YYYY")}{" - "}
                    {dayjs(option.e_date).format("MM/DD/YYYY")}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            {payrollPreLists.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={8}>
                <CurrentPreviousCard
                  item={item}
                  type="Previous"
                />
              </Col>
            ))}
          </Row>

          {payrollPreLists.length === 0 && !loading && <Empty />}
        </TabPane>
      </Tabs>
    </>

  );
};


const CurrentPreviousCard = ({ item, type }) => {
  return (
    <>
      <Card
        className="ant-tabs-tabpane"
        style={{
          background: "#f0f2f5",
          borderLeftWidth: "3px",
          borderLeftColor: "#1E90FF",
          width: "25em",
        }}
      >
        <Meta
          style={{ padding: "10px 10px" }}




          title={
            <>
              {type === "Current" && (
                <div
                  style={{
                    marginTop: "10px",
                    padding: "0 14px",
                    textWrap: "pretty",
                  }}
                >
                  {dayjs(item?.s_date).add(1, 'day').format("MM/DD/YYYY")} -{" "}
                  {dayjs(item?.e_date).add(1, 'day').format("MM/DD/YYYY")}{" "}
                  <span
                    style={{
                      fontSize: "0.6rem",
                      color: "#531dab",
                    }}
                  >
                    ({item?.payor} - {item?.Jtitle}){" "}
                  </span>
                </div>
              )}
              {type === "Previous" && (
                <div
                  style={{
                    marginTop: "10px",
                    padding: "0 14px",
                    textWrap: "pretty",
                  }}
                >
                  {dayjs(item?.s_date).add(1, 'day').format("MM/DD/YYYY")} -{" "}
                  {dayjs(item?.e_date).add(1, 'day').format("MM/DD/YYYY")}{" "}
                  <span
                    style={{
                      fontSize: "0.6rem",
                      color: "#531dab",
                    }}
                  >
                    ({item?.payor} - {item?.Jtitle}){" "}
                  </span>
                </div>
              )}
            </>
          }
          description={
            <div style={{ fontWeight: "500" }}>
              {type === "Current" && (
                <div
                  style={{
                    marginTop: 10,
                    padding: "0 14px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      fontWeight: "bold",
                    }}
                  >
                    <div>Payroll ID</div>
                    {item.n_rate && (
                      <div>Per Day Rate</div>
                    )}

                    <div>Per Day Rate</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      {item?.payroll_id}
                      {/* {Number.isInteger(item.tHours)
                   ? item.tHours
                   : item.tHours.toFixed(2)} */}
                    </div>
                    {item.n_rate && (
                      <div>
                        {/* {item?.n_rate} */}
                        {Number.isInteger(item.n_rate)
? item.n_rate
: item.n_rate.toFixed(2)}
                      </div>
                    )}

                    <div>
                      {Number.isInteger(item.cHour)
                   ? item.cHour
                   : item.cHour.toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
              {type === "Previous" && (
                <div
                  style={{
                    marginTop: 10,
                    padding: "0 14px",
                    marginBottom: "10px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                      fontWeight: "bold",
                    }}
                  >
                    <div>Payroll ID</div>
                    {item.n_rate && (
                        <div>Per Day Rate</div>
                    )}
                   
                    <div>Per Day Rate</div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-around",
                    }}
                  >
                    <div>
                      {item?.payroll_id}
                      {/* {Number.isInteger(item.tHours)
                    ? item.tHours
                    : item.tHours.toFixed(2)} */}
                    </div>
                   
                    <div>
                      {Number.isInteger(item.cHour)
                    ? item.cHour
                    : item.cHour.toFixed(2)}
                    </div>
                  </div>
                </div>
              )}
            </div>
          }
        />
      </Card>
    </>
  );
};


export default Earning;
