
export const CompanyDetails = () => {
    const USER_INFO = localStorage.getItem("USER_INFO");
  
    if (USER_INFO) {
      try {
        const userInfoObject = JSON.parse(USER_INFO);
        const companyDetails = userInfoObject.companyId;
        console.log(companyDetails);
        return companyDetails;
      } catch (error) {
        console.error("Error decoding authentication token:", error);
      }
    }
  
    return null;
  };
  
export const GetlocationFromLocalStorage = () => {
  const GetLoc = localStorage.getItem('location');
  console.log(GetLoc)
  let localStorgeLoc;
  if (GetLoc) {
     localStorgeLoc = JSON.parse(GetLoc);
     return localStorgeLoc;
  }else{
    return localStorgeLoc = { lat: '', long: '' };
  }
  };

  // const storedLatitude = localStorage.getItem("latitude");
  // const storedLongitude = localStorage.getItem("longitude");

  //     if (storedLatitude && storedLongitude) {
  //       setPosition({
  //         latitude: parseFloat(storedLatitude),
  //         longitude: parseFloat(storedLongitude),
  //       });