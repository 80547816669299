import React, { useEffect, useState } from 'react';
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { Row, Col, Badge, Card, Spin } from "antd";
import { message as messageApi } from 'antd';
import { RefreshComponet } from "../../utility/UserDetails";
import { useTranslation } from 'react-i18next';
function Medication() {
    const {t} = useTranslation();
    const { Meta } = Card;
    const [warningLoading, setWarningLoading] = useState(false);
    const [medicationList, setMedicationList] = useState([]);

    useEffect(() => {
        getMedications()
    }, []);


    const getMedications = () => {
        setWarningLoading(true);
        let endpoint = "client/medication";
        AxiosInstance.get(endpoint, '')
            .then(res => {
                setWarningLoading(false);
                if(res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)
                if (res.data.success) {
                    // Toaster("success", "Success", res.message);
                    setMedicationList(res.data.data);
                } else {
                    setMedicationList([])
                    Toaster("error", "Error", res.data.message);
                }

            })
            .catch(error => {
                setWarningLoading(false);
            });
    }


  
    return (
        <div>
            <Spin spinning={warningLoading} fullscreen size='large' />
            <Row
                gutter={[16, 16]}
                className="mt-3"
                style={{ margin: "0px 0px" }}
            >
                {medicationList.map((item, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
                        <Badge.Ribbon
                            placement="start"
                            text={t(item?.med_status)}
                            color={'cyan'}
                        >
                            <Card
                                className='ant-tabs-tabpane'
                                style={{
                                    background: "#f0f2f5",
                                    borderLeftWidth: "3px",
                                    borderLeftColor: "cyan"
                                }}

                            >
                                <Meta
                                    description={
                                        <div style={{fontWeight:'bold'}}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: "4px",
                                                    top: "0.5rem",
                                                }}
                                            >
                                            </div>
                                            <div
                                                style={{ marginTop: 33, marginLeft: '5px', marginBottom: '10px' }}>
                                                <div>
                                                    <span>
                                                        {item.medication || "N/A"}
                                                    </span>
                                                </div>
                                                <div>{t('Pharmacy')}:
                                                    <span>
                                                       {  }  {item.pharmacy || "N/A"}
                                                    </span>
                                                    <span style={{color:'blue'}}>
                                                      {  }   {item.status || "N/A"}
                                                    </span>
                                                </div>
                                                <div> {t('Route')}:
                                                    <span>
                                                      {  }  {item.route || "N/A"}
                                                    </span>
                                                </div>
                                                <div> {t('Frequency')}:
                                                    <span>
                                                     {  }    {item.frequency || "N/A"}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                />
                            </Card>
                        </Badge.Ribbon>
                    </Col>
                ))}
            </Row>

        </div>
    )
}

export default Medication