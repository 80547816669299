import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en, fr,hmk,ur,so,hi,vi,es,ko,lo,my,ru } from './index';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// const languageDetector = {
//   type: 'languageDetector',
//   async: true,
//     detect: async (callback) => {
//     try {
//       const storedLanguage = localStorage.getItem('language');
//       if (storedLanguage) {
//         callback(storedLanguage);
//       } else {
//         const { locale } = Localization.getLocales();
//         callback(locale);
//       }
//     } catch (error) {
//       // console.error('Error detecting language:', error);
//       const { locale } = Localization.getLocales();
//       callback(locale);
//     }
//   },
//   // detect: async (callback) => {
//   //   const { locale } = Localization.getLocales();
//   //   callback(locale);
//   // },
//   init: () => {},
//     cacheUserLanguage: (language) => {
//     localStorage.setItem('language', language).catch(error => {
//       // console.error('Error caching user language:', error);
//     });
//   },
// };

const resources = {
  en: { translation: en },
  fr: { translation: fr },
  hmk: { translation: hmk },
  ru: { translation: ru },
  ur: { translation: ur },
  so: { translation: so },
  hi: { translation: hi },
  vi: { translation: vi },
  es: { translation: es },
  ko: { translation: ko },
  lo: { translation: lo },
  my: { translation: my },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // Correct import for initializing React integration
  .init({
    fallbackLng: 'en',
    resources,
    debug: true,
    compatibilityJSON: 'v3',
    // lng:'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
