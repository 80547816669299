/* eslint-disable react/prop-types */
import React from "react";
import { Redirect } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const isAuthenticated = localStorage.getItem("token"); // Adjust based on how you handle authentication

  if (!isAuthenticated) {
    return <Redirect to="/sign-in" replace />;
  }

  return children;
};

export default ProtectedRoute;
