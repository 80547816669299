import React,{useState} from 'react';
import ChatBar from './ChatBar';
import ChatBody from './ChatBody';
import ChatFooter from './ChatFooter';
import "./../../assets/styles/chat.css";
import socketIO from 'socket.io-client';

// this.socket = io('http://192.168.1.13:3300/', {


const ChatPage = () => {
  const userType = localStorage.getItem('type') === 'e' ? '4' : '3';
  const userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
  const chatUrl = localStorage.getItem("chatUrl") || 'https://backend.revivingcare.us:3300/';
  const socket = socketIO.connect(chatUrl,{
  // const socket = socketIO.connect('https://backend.revivingcare.us:3300/',{
  // const socket = socketIO.connect('https://revivingcare.net:3302/',{
  query: {
    user_id: userId?._id,
    user_type: userType,
    company_id: userId?.companyId?._id
  },
  // transports: ['websocket', 'polling']
  transports: ["websocket"],
  upgrade: false
});
  // const [selectedUser, setSelectedUser] = useState(null); onUserSelect={handleUserSelect}
  // const handleUserSelect = (user) => {
  //   setSelectedUser(user); // Update selected user data in the parent component
  // };
  console.log(socket,"socket")
  return (
    <div className="chat">
      <ChatBar socket={socket} />
       <div className="chat__main">
        {/* <ChatBody socket={socket} selectedUser={selectedUser}/> */}
        {/* <ChatFooter socket={socket} selectedUser={selectedUser}  /> */}
      </div>
    </div>
  );
};

export default ChatPage;