import { useState, useEffect,useContext } from "react";
import { GlobalOutlined ,WechatOutlined,UserSwitchOutlined ,PhoneOutlined, LogoutOutlined,YoutubeOutlined ,SettingFilled,QuestionCircleFilled   ,ArrowLeftOutlined } from '@ant-design/icons';
import i18n from '../../utility/i18n'; // Ensure you have the correct path to your i18n configuration
import { useTranslation } from 'react-i18next';
import {
  Row,
  Col,
  Badge,
  Dropdown,
  Button,
  Drawer,
  Typography,
  Switch,
  Tooltip
} from "antd";
import styled from "styled-components";
import  HelpContent  from './HelpContent';
import { useLocation,useHistory } from "react-router-dom"
import LanguagePopup from '../../utility/LanguagePopup';
const ButtonContainer = styled.div`
  .ant-btn-primary {
    background-color: #1890ff;
  }
  .ant-btn-success {
    background-color: #52c41a;
  }
  .ant-btn-yellow {
    background-color: #fadb14;
  }
  .ant-btn-black {
    background-color: #262626;
    color: #fff;
    border: 0px;
    border-radius: 5px;
  }
  .ant-switch-active {
    background-color: #1890ff;
  }
`;
const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];



function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const headerMenu = subName.replace(/([a-z])([A-Z])/g, "$1 $2")
  const {t} = useTranslation();
  const { Title, Text } = Typography;
  const history = useHistory();
  const [open, setVisible] = useState(false);
  const [openHelp, setHelpVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");
  const [isLanguagePopupVisible, setIsLanguagePopupVisible] = useState(false);
  const [locale, setLocale] = useState(i18n.language || 'en');

  useEffect(() => window.scrollTo(0, 0));

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language');
    if (storedLanguage) {
      setLocale(storedLanguage);
      i18n.changeLanguage(storedLanguage);
    }
  }, []);

  const showDrawer = () => setVisible(true);
  const showHelpDrawer = () => setHelpVisible(true);
  const hideHelpDrawer = () => setHelpVisible(false);
  const hideDrawer = () => setVisible(false);

  const logout = () =>{
    i18n.changeLanguage("en");
    localStorage.clear();
    history.replace("/sign-in");
  }
  const twakTab = () => {
    window.open("https://tawk.to/chat/64ca023894cf5d49dc67eb5c/1h6qhhcg9", "_blank");
  };

  const handleOpenLanguagePopup = () => {
    setIsLanguagePopupVisible(true);
  };
  const handleCloseLanguagePopup = () => {
    setIsLanguagePopupVisible(false);
    setLocale(i18n.language)
  };


  const items = [
    {
      label: <a href="/profile">{t('Profile')}</a>,
      key: '0',
      icon: <UserSwitchOutlined style={{color:'blue'}}/>,
      
    },
    {
      type: 'divider',
    },
    {
      label: <a onClick={handleOpenLanguagePopup}>{t("Language")} ({locale})</a>,
      key: '0',
      icon: <GlobalOutlined style={{color:'blue'}}/>,
      
    },
    {
      type: 'divider',
    },
    {
      label: <a href="/contact">{t('Contact Us')}</a>,
      key: '1',
      icon: <PhoneOutlined style={{color:'blue'}} />,
    },
    {
      type: 'divider',
    },
    { 
      label: <span onClick={logout}>{t('Logout')}</span>,
      key: '3',
      icon: <LogoutOutlined style={{color:'blue'}} />,
    },
  ];

  const help = () =>{
    history.replace("/Help");
  }
  const chat = () =>{
    history.replace("/chat");
  }
  const home = () => {
    history.goBack();
    // history.replace("/home");
  }
  return (
    <>
      {/* <div className="setting-drwer" onClick={showHelpDrawer}>
        <InfoCircleOutlined />
      </div> */}
     
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}>
          {/* <Breadcrumb>
            <Breadcrumb.Item>
              <NavLink to="/">Pages</NavLink>
            </Breadcrumb.Item>
            <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
              {name.replace("/", "")}
            </Breadcrumb.Item>
          </Breadcrumb> */}
          <div onClick={home} className="ant-page-header-heading">
            <span
              className="ant-page-header-heading-title"
              style={{ textTransform: "capitalize" ,color:'white'}}>
             <span style={{marginRight:'10px',cursor: 'pointer'}}></span>{t(headerMenu)}<ArrowLeftOutlined
              style={{
                marginRight: "10px",
                float: "left",
                fontSize: "1.3rem",
                color: "#fff",
                cursor: "pointer",
              }} />
            </span>
          </div>
        </Col>
        <Col span={24} md={18} className="header-control">
        <Dropdown
    menu={{
      items,
    }}
    trigger={['click']}
  >
    <a onClick={(e) => e.preventDefault()}>
      <Tooltip  title="Profile">
      <UserSwitchOutlined  style={{color:'white'}}/>
      </Tooltip>
    </a>
  </Dropdown>
          {/* <Badge size="small" count={4}>
            <Dropdown menu={menu}  trigger={["click"]}>
              <a
                href="#pablo"
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                {bell}
              </a>
            </Dropdown>
          </Badge> */}
         
          <Button type="link" onClick={help}>
          <YoutubeOutlined />
          </Button>
          <Button type="link" onClick={showDrawer}>
           <SettingFilled />
          </Button>
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>
          <Drawer
            className="settings-drawer"
            mask={true}
            width={360}
            onClose={hideDrawer}
            placement={placement}
            open={open}
          >
            <div layout="vertical">
              <div className="header-top">
                <Title level={4}>
                  Configurator
                  <Text className="subtitle">See our dashboard options.</Text>
                </Title>
              </div>

              <div className="sidebar-color">
                <Title level={5}>Sidebar Color</Title>
                <div className="theme-color mb-2">
                  <ButtonContainer>
                    <Button
                      type="primary"
                      onClick={() => handleSidenavColor("#1890ff")}
                    >
                      1
                    </Button>
                    <Button
                      type="success"
                      onClick={() => handleSidenavColor("#52c41a")}
                    >
                      1
                    </Button>
                    <Button
                      type="danger"
                      onClick={() => handleSidenavColor("#d9363e")}
                    >
                      1
                    </Button>
                    <Button
                      type="yellow"
                      onClick={() => handleSidenavColor("#fadb14")}
                    >
                      1
                    </Button>

                    <Button
                      type="black"
                      onClick={() => handleSidenavColor("#111")}
                    >
                      1
                    </Button>
                  </ButtonContainer>
                </div>

                <div className="sidebarnav-color mb-2">
                  <Title level={5}>Sidenav Type</Title>
                  <Text>Choose between 2 different sidenav types.</Text>
                  <ButtonContainer className="trans">
                    <Button
                      type={sidenavType === "transparent" ? "primary" : "white"}
                      onClick={() => {
                        handleSidenavType("transparent");
                        setSidenavType("transparent");
                      }}
                    >
                      TRANSPARENT
                    </Button>
                    <Button
                      type={sidenavType === "white" ? "primary" : "white"}
                      onClick={() => {
                        handleSidenavType("#fff");
                        setSidenavType("white");
                      }}
                    >
                      WHITE
                    </Button>
                  </ButtonContainer>
                </div>
                <div className="fixed-nav mb-2">
                  <Title level={5}>Navbar Fixed </Title>
                  <Switch onChange={(e) => handleFixedNavbar(e)} />
                </div>
              </div>
            </div>
          </Drawer>
          <WechatOutlined  onClick={chat} style={{cursor:'pointer' ,color:'white'}}/>
          <Tooltip title="Help">
          <Button type="link" onClick={twakTab}>
           <QuestionCircleFilled    />
          </Button>
          </Tooltip>
      

          <Drawer title={subName.replace("/", "")} size="large" footer="Reimagined Homecare Software" onClose={hideHelpDrawer} open={openHelp}>
            <HelpContent type={subName.replace("/", "")} />
          </Drawer>
          {/* <Input
            className="header-search"
            placeholder="Type here..."
            prefix={<SearchOutlined />}
          /> */}
        </Col>
      </Row>
      <LanguagePopup
        visible={isLanguagePopupVisible}
        onClose={handleCloseLanguagePopup}
      />
    </>
  );
}

export default Header;
