import { notification } from "antd";

export const Toaster = (type, message, description,icon="") => {
  notification[type]({
    message: message,
    description: description,
    icon: icon
  });
};



