import React, { useEffect, useState ,lazy ,Suspense} from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/home/Home";
import ScheduleDetails from "./pages/home/ScheduleDetails";
import SignIn from "./pages/SignIn";
import Schedular from "./pages/Schedular/Schedular";
import Manual from "./pages/ManualEntry";
import Multi from "./pages/MultiScheduler";
import Main from "./components/layout/Main";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import ProtectedRoute from "./authentication/protected-route/ProtectedRoutes";
import PageNotFound from "./pages/PageNotFound";
import History from "./pages/History";
import Pin from "./pages/Pin";
import Earning from "./pages/Earning/Earning";
import Vacation from "./pages/vacations/Vacation";
import TraningCourses from "./pages/traning-courses/TraningCourses";
import openScheduler from "./pages/Open-Schedular/OpenSchedular";
import Availability from "./pages/Availability/Availability";
import Emar from "./pages/Emar/Emar";
import CarePlans from "./pages/care-plans/CarePlans";
import Medication from "./pages/medication/Medication";
import Services from "./pages/services/Services";
import Clients from "./pages/Clients/Clients";
import ClientDetails from "./pages/Clients/ClientDetails";
import ClinicalForms from "./pages/Clients/ClinicalForms";
import Contact from "./components/contact/ContactUs";
import Profile from "./components/userProfile/Profile";
import ChatPage from "./pages/chat/Chat";
import ChatBody from "./pages/chat/ChatBody";
import Help from "./pages/Help/Help";
import Hospitalization from "./pages/hospitalization/Hospitalization";
import './utility/i18n'; // Ensure i18n is imported


const App = () => {
  // const Home = lazy (() => import("./pages/home/Home"))
  // const History = lazy (() => import("./pages/History"))
  // const Earning = lazy (() => import("./pages/Earning/Earning"))
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  // const [platform, setPlatform] = useState('');
  useEffect(() => {
    // debugger
    // const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // const isAndroid = /Android/.test(navigator.userAgent) && !window.MSStream;
    // const isMobile = isIOS || isAndroid

    // if (isMobile) {
    //   setPlatform('Mobile');
    // } else {
    //   setPlatform('Desktop');
    // }
    // console.log(platform,'User is accessing from')
    const token = localStorage.getItem("token");
    const pin = localStorage.getItem("pin");

    // Check if both token and pin exist
    if (token && pin) {
      setIsLoggedIn(true);
    }
  }, []);

  return (
    <div className="App">
      <Switch>
       
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/" exact>
          {isLoggedIn ? <Redirect to="/pin" /> : <Redirect to="/sign-in" />}
        </Route>
        <Route path="/pin" exact component={Pin} />

        <Main>
          <ProtectedRoute>
          {/* <Suspense fallback={<h1>Loading...........</h1>} > */}
            <Switch>
    
              <Route exact path="/home" component={Home} />
              <Route exact path="/home/:id" component={Home} />
              <Route exact path="/history" component={History} />
              <Route exact path="/schedular" component={Schedular} />
              <Route exact path="/manualEntry" component={Manual} />
              <Route exact path="/multiScheduler" component={Multi} />
              <Route exact path="/ScheduleDetails" component={ScheduleDetails} />
              {/* <Route exact path="/chat_body" component={ChatBody} /> */}
              <Route exact path="/chat_body/:id" component={ChatBody} />
              <Route exact path="/earning" component={Earning} />
              <Route exact path="/trainingCourses" component={TraningCourses} />
              <Route exact path="/openScheduler" component={openScheduler} />
              <Route exact path="/availability" component={Availability} />
              <Route exact path="/Emar" component={Emar} />
              <Route exact path="/vacation" component={Vacation} />

              {/* clients */}
              <Route exact path="/carePlans" component={CarePlans} />
              <Route exact path="/services" component={Services} />
              <Route exact path="/hospitalization" component={Hospitalization} />
              <Route exact path="/medication" component={Medication} />
              <Route exact path="/Clients" component={Clients} />
              <Route path="/Clients/client/:id" component={ClientDetails} />
              <Route path="/Clients/clinical/:id" component={ClinicalForms} />
              <Route path="/Contact" component={Contact} />
              <Route path="/Profile" component={Profile} />
              <Route path="/Chat" component={ChatPage} />
              <Route path="/Help" component={Help} />
              <Route component={PageNotFound} />
            
            </Switch>
            {/* </Suspense> */}
          </ProtectedRoute>
        </Main>
     
      </Switch>
    </div>
  );
};

export default App;
