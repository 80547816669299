import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper } from 'google-maps-react';
import { LoadingOutlined, EnvironmentTwoTone } from "@ant-design/icons";
import{Toaster} from '../utility/Toaster'
const MyLocation = ({google}) => {
    const [position, setPosition] = useState({ latitude: null, longitude: null });
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState('');

    // const fetchGeolocation = async () => {
    //     try {
    //         if ("geolocation" in navigator) {
    //             const position = await new Promise((resolve, reject) => {
    //                 navigator.geolocation.getCurrentPosition(resolve, reject);
    //             });
    //             return {
    //                 latitude: position.coords.latitude,
    //                 longitude: position.coords.longitude,
    //                 error: null // No error
    //             };
    //         } else {
    //             return {
    //                 latitude: null,
    //                 longitude: null,
    //                 error: "Geolocation is not available in your browser."
    //             };
    //         }
    //     } catch (error) {
    //         console.error("Error fetching geolocation:", error.message);
    //         return {
    //             latitude: null,
    //             longitude: null,
    //             error: "Error fetching geolocation: " + error.message
    //         };
    //     }
    // };
    
    const fetchGeolocationInComponent = async () => {
        try {
            if ("geolocation" in navigator) {
                setLoading(true); // Set loading state to true
                const position = await new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                });
                setPosition({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
                setTimeout(() => {
                    setLoading(false); // Set loading state to false after fetch is complete
                }, 2000);
            } else {
                
                console.log("Geolocation is not available in your browser.");
                setLoading(false); // Set loading state to false if geolocation is not available
            }
        } catch (error) {
            
            Toaster("error", "Error", error.message);
            console.error("Error fetching geolocation:", error.message);
            setLoading(false); // Set loading state to false if an error occurs
        }
    };
    
    useEffect(() => {
        fetchGeolocationInComponent();
    }, []); // Run the fetchGeolocation function only once on component mount
    useEffect(() => {
        if(position.latitude && position.longitude){
        const geocoder = new google.maps.Geocoder();
        const latlng = { lat: parseFloat(position.latitude), lng: parseFloat(position.longitude) };
    
        geocoder.geocode({ 'location': latlng }, (results, status) => {
          if (status === 'OK') {
            if (results[0]) {
              setAddress(results[0].formatted_address);
            } else {
              console.log('No results found');
            }
          } else {
            console.log('Geocoder failed due to: ' + status);
          }
        });
    }
    }, [position]);
    // Render loading indicator while fetching geolocation
    if (loading) {
        // return <div>Loading...</div>;
        return <>
            <LoadingOutlined /> Fetching Location...

        </>
    }

    return (
        <>
            {/* <h2>My Current Location</h2> */}
            {position.latitude && position.longitude ? (
                <span>
                <EnvironmentTwoTone />    {address}
                    {/* {position.latitude}, Longitude: {position.longitude} */}
                </span>
            ) : (
                <span>Location is off</span>
            )}
        </>
    );
}
export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
  })(MyLocation);
