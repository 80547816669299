import React, { useState, useEffect } from 'react';
import { GoogleApiWrapper, Map, Marker } from 'google-maps-react';
import fetchGeolocation from "./../../utility/CurrentLocation";
const MapWithMarkers = ({ google, markers }) => {
  console.log(markers,"markers")
  const location = JSON.parse(localStorage.getItem("location"));
  const [currentLocation, setCurrentLocation] = useState({ lat: markers[0].lat || parseFloat(location.lat), lng:  markers[1].lng || parseFloat(location.long) });
  const [mapMarkers, setMapMarkers] = useState([]);
  useEffect(() => {
    setMapMarkers(markers.map((marker, index) => (
      <Marker
        key={index}
        position={{ lat: marker.lat, lng: marker.lng }}
        icon={{
           url: marker.icon,
           scaledSize: new google.maps.Size(25, 25) // Adjust the size of the image as needed
        }}
      />
    )));
  }, [markers]);

  useEffect(() => {
    fetchGeoloc();
  }, []);

  const fetchGeoloc = async () => {
    try {
      const location = JSON.parse(localStorage.getItem("location"));
      if (location) {
        setCurrentLocation({
           lat: markers[0].lat || parseFloat(location.lat),
           lng:  markers[1].lng || parseFloat(location.long) 
        })
        // setCurrentLocation({
        //   lat: parseFloat(location.lat),
        //   lng: parseFloat(location.long),
        // });
      } else {
        const fetchedPosition = await fetchGeolocation();
        setCurrentLocation({
          lat: fetchedPosition.latitude,
          lng: fetchedPosition.longitude,
        });
      }
    } catch (error) {
      console.error("Error fetching geolocation:", error.message);
    }
  };

  return (
    <Map
      style={{ maxWidth: '90%', height: '300px' }}
      google={google}
      zoom={8}
      initialCenter={currentLocation}
    >
      {mapMarkers}
       {/* <button 
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              zIndex: '1000'
            }}
            onClick={fetchGeoloc}
          >
            <img 
              src="https://maps.gstatic.com/mapfiles/refresh.png" 
              alt="Recenter" 
              width="24" 
              height="24" 
            />
          </button>  */}
    </Map>
  );
};

export default GoogleApiWrapper({
  // apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY
})(MapWithMarkers);
