import { message } from 'antd';

export const showMessage = (type, content, duration = 3) => {
  message[type](content, duration);
};

export const Message = {
  success: (content, duration = 3) => showMessage('success', content, duration),
  error: (content, duration = 3) => showMessage('error', content, duration),
  info: (content, duration = 3) => showMessage('info', content, duration),
  warning: (content, duration = 3) => showMessage('warning', content, duration),
};
