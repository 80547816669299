import React, { useEffect, useState } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { RefreshComponet } from "../../utility/UserDetails";
import {
  Card,
  Col,
  Row,
  Spin,
  Tag,
  Empty,
  Tabs,
  Modal,
  Form,
  Input,
  Button,
  InputNumber,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { PlusCircleTwoTone, UploadOutlined } from "@ant-design/icons";
import { Message } from "../../utility/MessageUtil";
import { useTranslation } from "react-i18next";
const { TabPane } = Tabs;
const { Meta } = Card;

const TraningCourses = () => {
  const {t} = useTranslation();
  const [traningList, setTraningList] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    getTraningCoures();
  }, []);

  const getTraningCoures = () => {
    const local = "emp/trainings";
    setLoading(true);
    AxiosInstance.get(local)
      .then((response) => {
        setLoading(false);
        if(response.data.dirtyBit) RefreshComponet(response.data.dirtyBit)
        const data = response.data;
        if (data.success) {
          setTraningList(data.data.trainings);
          setCoursesList(data.data.courses);
        } else {
          setTraningList([]);
          setCoursesList([]);
          // Handle error or display message
        }
      })
      .catch((error) => {
        setLoading(false);
        Toaster("error", "Error", error.message);
        // Handle error or display message
      });
  };

  const handleAddDetails = (id, type) => {
    const item =
      type === "Training"
        ? traningList.find((item) => item._id === id)
        : coursesList.find((item) => item._id === id);
    setModalData({ ...item, type });
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const handleModalSubmit = (values) => {
    const obj = {
      ...values,
      id: modalData._id,
    };
  
    // Set attach value based on values.attach
    // obj.attach = values.attach?.[0]?.thumbUrl || '';
  
    // Set type based on modalData.type
    obj.type = modalData.type === 'Courses' ? 'course' : 'training';
    if(!obj.attach) delete obj.attach;
    const local = 'emp/complete';
    setbuttonLoading(true);
  
    AxiosInstance.put(local, obj)
      .then((response) => {
        setbuttonLoading(false);
        const data = response.data;
        if (data.success) {
          Message.success(data.message);
          form.resetFields();
          setModalVisible(false);
          getTraningCoures();
        } else {
          // Handle other cases if needed
        }
      })
      .catch((error) => {
        setbuttonLoading(false);
        Toaster('error', 'Error', error.message);
        // Handle error or display message
      });
  };
  

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };


  const handleChange = (info) => {
    const { file } = info;
    if (file.status === 'error') {
      const reader = new FileReader();
      reader.onload = (event) => {
        console.error('File upload failed:', event.target.result);
        form.setFieldsValue({attach:event.target.result})
      };
      reader.readAsDataURL(file.originFileObj);
    } else if (file.status === 'error') {
      console.error('File upload failed:', file.error);
      // Handle error case here, if needed
    }
    // else{
    //   if(file){
    //   const reader = new FileReader();
    //   reader.onload = (event) => {
    //     console.error('File upload failed:', event.target.result);
    //     form.setFieldsValue({attach:event.target.result})
    //   };
    //   reader.readAsDataURL(file.originFileObj);
    // }
    // }
  };
  return (
    <>
      <Spin
        size="large"
        spinning={loading}
        fullscreen
        tip={t('Fetching Your Data')}

      />
      <Tabs defaultActiveKey="1" type="card" left size="large">
        <TabPane tab={t('Training')} key="1">
          <Row gutter={[16, 16]}>
            {traningList.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={8}>
                <TrainingCoursesCard
                  item={item}
                  type="Training"
                  AddDetails={handleAddDetails}
                />
              </Col>
            ))}
          </Row>
          {traningList.length === 0 && <Empty />}
        </TabPane>
        <TabPane tab={t('Courses')} key="2">
          <Row gutter={[16, 16]}>
            {coursesList.map((item, index) => (
              <Col key={index} xs={24} sm={12} md={8} lg={8}>
                <TrainingCoursesCard
                  item={item}
                  type="Courses"
                  AddDetails={handleAddDetails}
                />
              </Col>
            ))}
          </Row>
          {coursesList.length === 0 && <Empty />}
        </TabPane>
      </Tabs>

      <Modal
        title={t('Add Details')}
        closeIcon={false}
        open={modalVisible}
        maskClosable={false}
        onCancel={handleModalCancel}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleModalSubmit}
          initialValues={
            {
              /* Initialize form values here if needed */
            }
          }
        >
          <Form.Item
            name="attach"
            valuePropName="fileList"
            getValueFromEvent={normFile}
          >
            <div style={{textAlign:'center'}}>
            <Upload style={{marginRight:'10px'}} name="logo" listType="picture" maxCount={1} accept="image/png, image/jpeg" beforeUpload={() => true} onChange={handleChange} onRemove={(file) => {
                   form.setFieldsValue({attach:""})
              }}>
                {/* beforeUpload={() => false} */}
              <Button icon={<UploadOutlined />} block size="large">{t('Upload')}</Button>
            </Upload></div>
          </Form.Item>

          <Form.Item
            label={t('Points')}
            name="points"
            rules={[
              {
                required: true,
                message:t('Points'),
              },
            ]}
          >
            <InputNumber min={0} style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item label={t('Notes')} name="note">
            <Input.TextArea />
          </Form.Item>

          <Form.Item>
            <div style={{display:'flex',flexDirection:'row',justifyContent:'flex-end',gap:'8px'}}>
            <Button type="primary" className="ant-btn-modal" htmlType="submit" loading={buttonLoading}>
            {t('Update')}</Button>
            <Button type="primary" danger className="ant-btn-modal" onClick={handleModalCancel} htmlType="reset">
            {t('Cancel')} 
            </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

const TrainingCoursesCard = ({ item, type, AddDetails }) => {
  return (
    <Card
      className="ant-tabs-tabpane"
      style={{
        background: "#f0f2f5",
        borderLeftWidth: "3px",
        borderLeftColor: "#1E90FF",
        width: "25em",
      }}
    >
      <Meta
        style={{ padding: "10px 10px" }}
        title={
          <>
            <div>
              <Tag
                color="blue"
                style={{ float: "inline-end", cursor: "pointer" }}
                onClick={() => AddDetails(item._id, type)}
              >
                <PlusCircleTwoTone />
              </Tag>
            </div>
            {type === "Training" && (
              <div>
                { item.initiate_dt ? dayjs(item?.initiate_dt).add(1, 'day').format('MM/DD/YYYY') : ''} -  { item.expiry_dt ? dayjs(item?.expiry_dt).add(1, 'day').format('MM/DD/YYYY') : ""}                
                
                <div style={{
                    fontSize: ".9em",
                    color: "#8546A7",
                    fontVariantCaps: "petite-caps",
                    textWrap: "pretty"
                  }}>
                Qualify Points: {item.trainingqualify || "N/A"}
                </div>
              </div>
            )}
            {type === "Courses" && (
              <div>
               { item.complete_dt ? dayjs(item?.complete_dt).add(1, 'day').format('MM/DD/YYYY') : ""}  
          
                <div style={{
                    fontSize: ".9em",
                    color: "#8546A7",
                    fontVariantCaps: "petite-caps",
                    textWrap: "pretty"
                  }}>
         Qualify Points: {item.coursesqualify || "N/A"}
                </div>
              </div>
            )}
          </>
        }
        description={
          <div style={{ fontWeight: "500" }}>
            {type === "Training" && (
              <div style={{ color: "#8546A7" }}>{item.training}</div>
            )}
            {type === "Courses" && (
              <div style={{ color: "#8546A7" }}>{item.course_title}</div>
            )}
          </div>
        }
      />
    </Card>
  );
};

export default TraningCourses;
