import React, { useEffect, useState, useRef, } from 'react';
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { GetlocationFromLocalStorage } from "../../utility/CompanyDetails";
import { RefreshComponet } from "../../utility/UserDetails";
import fetchGeolocation from "../../utility/CurrentLocation";
import SignatureCanvas from 'react-signature-canvas';
import SignatureModal from '../home/Cli-Emp-Signatures';
import { Row, Col, Button, Select, Input, Typography, Alert, DatePicker, Badge, Checkbox, Card, Spin, Modal, Empty } from "antd";
import { message as messageApi } from 'antd';
import moment from 'moment';
import dayjs from 'dayjs';
import { useTranslation } from "react-i18next";
import { useSwipeable } from 'react-swipeable';
const dateFormat = 'YYYY-MM-DD';

function Schedular() {
  const { Title } = Typography;
  const { Meta } = Card;
  const { Option } = Select;
  const clientSigCanvas = useRef();
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [clientList, setClientList] = useState([]);
  const [items, setItems] = useState([]);
  const [selectAllActive, setSelectAllActive] = useState(true);
  const [warningLoading, setWarningLoading] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showEmployeeSignature, setShowEmployeeSignature] = useState(false);
  const [showClientSignature, setShowClientSignature] = useState(false);
  const [getDataForClientPin, setDataForClientPin] = useState(null);
  const [showClientSignatureBox, setShowClientSignatureBox] = useState(false);
  const [getPinValue, setPinValue] = useState('');
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);
  const { t } = useTranslation();
  const [schData, setSchData] = useState({
    clientId: '',
    sDate: '',
    eDate: '',
    eSign: "",
    lat: "",
    lng: "",

  });



  useEffect(() => {
    // const UserInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    // const state = UserInfo.companyId.state || '';
    const state = localStorage.getItem("state") || "";

    const isAllowedPayrollGracePeriod =  localStorage.getItem("isAllowedPayrollGracePeriod")
    const payrolGracePeriod =  localStorage.getItem("payrolGracePeriod")
    const payrloGPeriod = isAllowedPayrollGracePeriod && payrolGracePeriod ? payrolGracePeriod : 0;
    console.log('\n payrolGracePeriod = ', payrloGPeriod);

    if (state === 'IL') {
      setSchData(prevState => ({
        ...prevState,
        sDate: dayjs(),
        eDate:dayjs()
      }));
      setMinDate(dayjs().startOf('day').toDate());
      setMaxDate(dayjs(new Date(2027, 0, 1)).toDate());
    } else {
      setMinDate(dayjs(new Date(2019, 0, 1)).toDate());
      setMaxDate(dayjs(new Date(2027, 0, 1)).toDate());
    }
    // setWarningLoading(true);
    if(state !== 'IL') getEmpCutSchHr(payrloGPeriod);
    const url = `emp/my-client`;
    AxiosInstance.get(url)
      .then(res => {
        setWarningLoading(false);
        if(res.data.dirtyBit) RefreshComponet(res.data.dirtyBit)
        const clients = res.data.data;
        setClientList(clients);
        if (clients.length == 1) {
          setSchData(prevState => ({
            ...prevState,
            clientId: clients[0]._id
          }));
        }
      })
      .catch(error => {
        setWarningLoading(false);
      });
      
  }, []);


  useEffect(() => {
    getSchedules();
  }, [schData.sDate, schData.eDate, schData.clientId]);

  const getColor = (c) => {
    switch (c.scheduleStatus) {
      case "New":
        return "teal";
      case "Completed":
        return "rgb(50, 205, 50)";
      case "Ended":
        return "#808080";
      case "Cancelled":
        return "#FF0000";
      case "Inprogress":
        return "#F2AA4CFF";
      case "Finished":
        return "#A020F0";
      case "Unverified":
        return "#000000";
      default:
        return "#1E90FF";
    }
  };
  const getEmpCutSchHr = (payrloGPeriod) => {
        // setWarningLoading(true);
    let endPoint = "emp/cut-sch-hours";
    // AxiosInstance.get(endPoint, { params: { date: dayjs().subtract(3, 'day').format('MM-DD-YYYY') } })
    AxiosInstance.get(endPoint, {
      params: { date: moment(new Date()).subtract(payrloGPeriod || 0, 'day').format('MM/DD/YYYY')} 
    })
      .then((res) => {
        if (res.data.success) {
          const data = res.data.data;
          if (data.eDate && data.sDate) {
            setSchData(prevState => ({
              ...prevState,
              sDate: moment(data.sDate).add(1, 'days').format('MM/DD/YYYY'),
              eDate: moment(data.eDate).format("MM/DD/YYYY")
            }));
            // schData.sDate = dayjs(res.data.sDate).format("YYYY-MM-DD");
            // schData.eDate = dayjs(res.data.eDate).format("YYYY-MM-DD");
          } else {
            setSchData(prevState => ({
              ...prevState,
              sDate: dayjs(),
              eDate:dayjs()
            }));
          }
        } else {
          setSchData(prevState => ({
            ...prevState,
            sDate: dayjs(),
            eDate:dayjs()
          }));
        }
      })
      .catch((error) => {
      });
  };


  const getSchedules = () => {
    let StartDate = dayjs(schData.sDate).format("YYYY-MM-DD");
    let EndDate = dayjs(schData.eDate).format("YYYY-MM-DD");
    let UserInfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (!schData.clientId) {
      return
    }
    setWarningLoading(true);
    if(StartDate == 'Invalid Date') return;
    let endpoint = `emp/sch-list?companyId=${UserInfo.companyId._id}&status=${'New'}&sDate=${StartDate}&eDate=${EndDate}&clientId=${schData.clientId}`;
    AxiosInstance.get(endpoint)
      .then(res => {
        setWarningLoading(false);
        if (res.data.success) {
          // Toaster("success", "Success", res.message);
          // setItems(res.data.data);
          if (res && res.data.data && Array.isArray(res.data.data)) {
            res.data.data.forEach(x => {
              if (x.aSTime && x.aETime) {
                let start = moment.utc(x.aSTime, "HH:mm A");
                let end = moment.utc(x.aETime, "HH:mm A");
                let d = moment.duration(end.diff(start));
                let time = d['_data'];
                x.duration = time.hours + ":" + time.minutes;
              } else {
                if (x.sTime && x.eTime) {
                  let start = moment.utc(x.sTime, "HH:mm A");
                  let end = moment.utc(x.eTime, "HH:mm A");
                  let d = moment.duration(end.diff(start));
                  let time = d['_data'];
                  x.duration = time.hours + ":" + time.minutes;
                }
              }
            });
          }
          setItems(res.data.data);
          setShowDeleteButtonId(null);
        } else {
          setItems([])
          Toaster("error", "Error", res.data.message);
        }

      })
      .catch(error => {
        setWarningLoading(false);
      });
  }

  const startMultiSch = async () => {
    setWarningLoading(true);
    const localStorgeLoc = await GetlocationFromLocalStorage();
    if (localStorgeLoc && !localStorgeLoc.lat) {
      const location = await fetchGeolocation();
      if (location) {
        if (location.latitude && location.longitude) {
          localStorgeLoc.lat = location.latitude;
          localStorgeLoc.long = location.longitude;
          schData.lat = localStorgeLoc.lat;
          schData.lng = localStorgeLoc.long;
          localStorage.setItem('location', JSON.stringify(localStorgeLoc));
        }
        else {
          Toaster("error", "Error", t("Your device is unable to pick your location, Please check your device settings"));
        }
      }
    } else {
      schData.lat = localStorgeLoc.lat;
      schData.lng = localStorgeLoc.long;
    }

    let endPoint = `emp/start-multi-scheduler`;
    let signatureData = {
      ids: selectedIds,
      lat: schData.lat,
      lng: schData.lng,
      bypass: false,
      bypassTC: false,
    }
    AxiosInstance.put(endPoint, signatureData)
      .then(({ data }) => {
        setWarningLoading(false);
        if (data.success) {
          Toaster("success", "Success", data.message);
          setShowEmployeeSignature(true);
        }
        else {
          Toaster("error", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);
        setWarningLoading(false);
      });

  }

  const handleChange = (value, field) => {
   if(!value) return;
   else{
    setSchData(prevState => ({
      ...prevState,
      [field]: value
    }));
   }
  };
  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelectedIds) => {
      if (prevSelectedIds.includes(id)) {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      } else {
        return [...prevSelectedIds, id];
      }
    });
  };
  const handleSelectAll = () => {
    const allIds = items.map((item) => item._id);
    setSelectedIds(allIds); // Update selectedIds with all IDs from data
    setSelectAllActive(false); // Hide "Select All" button
  };
  const handleDeselectAll = () => {
    setSelectedIds([]);
    setSelectAllActive(true); // Hide "Deselect All" button
  };
  const closeSignatureModal = () => {
    getSchedules();
    setShowEmployeeSignature(false);
    setShowClientSignature(false);
    setSelectedIds([])
  }
  const getEmpSignature = (URL) => {
    schData.eSign = URL
    employeeSignature();
  };

  const getClientSignature = (type) => {
    // schData.cSign = URL;
    schData.cSign = type.getTrimmedCanvas().toDataURL("image/png");
    clientSignature();
  }
  const clearCanvas = () => {
    clientSigCanvas.current.clear();
    setIsSignatureEmpty(true);
  };
  const handleDraw = () => {
    setIsSignatureEmpty(false); // Set signature as not empty when drawing starts
  };
  const employeeSignature = () => {
    setWarningLoading(true);
    let endPoint = 'emp/multiple-schedule'
    let signatureData = {
      ids: selectedIds,
      eSign: schData.eSign,
      lat: schData.lat,
      lng: schData.lng
    }
    AxiosInstance.post(endPoint, signatureData)
      .then(({ data }) => {
        setWarningLoading(false);
        if (data.success) {
          setShowEmployeeSignature(false);
          messageApi.success(data.message);
          if (data.data && data.data.cSig && data.data.cSignService) {
            setDataForClientPin(selectedIds[0]);
            setShowClientSignature(true);
          }
          else if (data.data && data.data.cSig) {
            setShowClientSignature(true)
            setShowClientSignatureBox(true);
        } 
        getSchedules();
        } else {
          Toaster("error", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);
        setWarningLoading(false);
      });

    //  setShowEmployeeSignature(false);
    // Toaster("success", "Signature Successfully Saved", "");
  }
  const clientSignature = () => {
    setWarningLoading(true);
    let endPoint = 'client/multi-sign'
    let signatureData = {
      ids: selectedIds,
      cSign: schData.cSign,
      lat: schData.lat,
      lng: schData.lng
    }
    AxiosInstance.post(endPoint, signatureData)
      .then(({ data }) => {
        setWarningLoading(false);
        if (data.success) {
          setShowClientSignature(false);
          Toaster("success", "Success", data.message);
          setPinValue("")
          setDataForClientPin("")
          setShowClientSignature("")
          setSelectedIds([])
          setShowDeleteButtonId(true);
          setShowClientSignatureBox('')
          clearCanvas()
          getSchedules()
        } else {
          Toaster("info", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);
        setWarningLoading(false);
      });
  }

  const handlePinChange = (e) => {
    const newPinValue
      = e.target.value;
    if (newPinValue.length === 4) {
      // If 4 digits are entered, trigger the API call
      setWarningLoading(true); // Set loading state to true
      let UserInfo = JSON.parse(localStorage.getItem("USER_INFO"));
      let endPoint = { e: 'emp/verify-pin', c: 'client/verify-pin' };
      let local = UserInfo.type ? endPoint[UserInfo.type] : '';
      let obj = {
        id: getDataForClientPin,
        pin: newPinValue, // Use the entered PIN value
      };
      AxiosInstance.post(local, obj)
        .then(({ data }) => {
          setWarningLoading(false); // Set loading state to false
          if (data.success) {
            setShowClientSignatureBox(true)
            messageApi.success(data.message);
          } else {
            messageApi.error(data.message);
          }
        })
        .catch((err) => {
          messageApi.error(err.message);
          setWarningLoading(false); // Set loading state to false
        });
    }
    setPinValue(newPinValue); // Update PIN value in state
  };

  const [showDeleteButton, setShowDeleteButtonId] = useState(false);

  const swipeHandlers = useSwipeable(
    { onSwipedLeft: (eventData) => { 
      const closestCard = eventData.event.target.closest('.swipeable-card');
      const data = closestCard.dataset.item;
      const convertData = JSON.parse(data);
       setShowDeleteButtonId(convertData._id); 
       
       setSelectedIds([...selectedIds, convertData._id]); 
      //  setClientsIds([...clientsIds, data.clientId._id]);
      },
        onSwipedRight: () => { 
          setShowDeleteButtonId(null); 
          setSelectedIds([])
        },
         preventDefaultTouchmoveEvent: true, trackMouse: true, });
         
  const ProcessClick = () => {
    startMultiSch()
  };

  return (
    <div>
       <Spin spinning={warningLoading} fullscreen tip={ t("FetchingYourData")} size='large' />
      {items.length !== 0 &&
        <Alert
          message={
            <div style={{ float: 'inline-end' }}>

              {selectedIds?.length ? (
               !showDeleteButton &&
               <Button type="primary" className='ant-btn-form' onClick={startMultiSch} style={{marginRight: 10 , backgroundColor:'#32CD32'}}>
                
                   { t("Process")}
                </Button>
              ) : null}


              {items.length ? (
                <>
                  {selectAllActive ? (
                    <Button type="primary" className='ant-btn-form'  onClick={handleSelectAll}>
                      { t("SelectAll")}
                    </Button>
                  ) : (
                    <Button type="primary" danger className='ant-btn-form' onClick={handleDeselectAll} >
                     { t("UnselectAll")}
                    </Button>
                  )}
                </>
              ) : null}
            </div>
          }
          type="info"
        />
      }

       {schData.sDate &&  <Row gutter={4} style={{ marginTop: 10, marginBottom: 10, marginLeft: 2, fontWeight: 'bold' }}>
        <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
          <div><label>{ t("StartDate")}</label></div>
         <DatePicker defaultValue={dayjs(schData.sDate, 'MM/DD/YYYY')}
            onChange={date => handleChange(date, 'sDate')}
            placeholder={t("Select Start Date")}
            format={'MM/DD/YYYY'}
            disabledDate={current => current && current < minDate || current > maxDate}
          />
        </Col>
        <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
          <div><label>{ t("EndDate")}</label></div>
          <DatePicker
            defaultValue={dayjs(schData.eDate, 'MM/DD/YYYY')}
            onChange={date => handleChange(date, 'eDate')}
            format={'MM/DD/YYYY'}
            placeholder={t("Select End Date")}
            disabledDate={current => current && current < minDate || current > maxDate}
          />
        </Col>
        <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
          <div><label>{t("SelectClient")}</label></div>
          <Select
            style={{ width: '100%' }}
            showSearch
            // placeholder="Select Client"
            // value={schData.clientId}
            // value={clientList.length == 1  ? clientList[0]._id : schData.clientId}
            value={schData.clientId ? schData.clientId : (clientList.length === 1 ? clientList[0]._id : 'Select Client')}

            onChange={value => handleChange(value, 'clientId')}
          >
            {clientList.map(option => (
              <Option key={option._id} value={option._id}>
                {option.fName} {option.lName}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>}

     
      <Row
        gutter={[16, 16]}
        className="mt-3"
        style={{ margin: "0px 0px" }}
      >
        {items.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
            <Badge.Ribbon
              placement="start"
              text={t(item.scheduleStatus)}
              color={getColor(item)}
            >
                  <div className="swipeable-card" >  
                  
              <Card
                className='ant-tabs-tabpane swipeable-card'
                style={{
                  background: "#f0f2f5",
                  borderLeftWidth: "3px",
                  borderLeftColor:getColor(item)
                   
                }}
                
                data-item={JSON.stringify(item)}
                 {...swipeHandlers}
                // data-item-id={item.id}
              >
                <Meta
                  title={
                    <div style={{}}>
                      <div
                        style={{
                          position: "absolute",
                          right: "4px",
                          top: "0.5rem",
                        }}
                      >
                        <Checkbox
                          checked={selectedIds.includes(item._id)}
                          onChange={() => handleCheckboxChange(item._id)}
                        ></Checkbox>
                      </div>
                      <div
                        style={{ marginTop: 33, marginLeft: '5px', marginBottom: '10px' }}>
                        <div>
                        {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                          {/* {dayjs(item?.eDate).format('MMM DD (ddd)')} */}
                        </div>
                        <div>
                          <span>
                            {" "}
                            {item?.aSTime || item?.sTime} - {item?.aETime || item?.eTime}
                          </span>
                          <span
                            style={{ fontSize: "0.7rem", color: "#8546A7" }}
                          >
                            {" "}
                            {item?.service?.desc}
                          </span>
                        </div>
                        <div>
                          <span>
                               {item?.clientId ? (
               <span>{item?.clientId?.fName} {item?.clientId?.lName}</span>
            ) : <span>{item?.fName} {item?.lName}</span>
            }
           
                            {/* {item?.clientId?.fName} {item?.clientId?.lName}
                            {item?.fName} {item?.lName} */}
                          </span>

                        </div>
                        <div>{t("Duration")}:
                          <span style={{ color: "#8546A7" }}>
                            { } {item.duration}
                          </span>
                        </div>
                      </div>
                    </div>
                  }
                />
                 {showDeleteButton == item._id && (
      <div
        style={{
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'green',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderTopRightRadius: '9px',
          borderBottomRightRadius: '9px',
        }}
      >
        <button
          onClick={ProcessClick}
          style={{
            color: 'white',
            background: 'green',
            border: 'none',
            padding: '8px 16px',
            cursor: 'pointer',
          }}
        >
          {t("Process")}
        </button>
      </div>
    )}
              </Card></div>
            </Badge.Ribbon>
          </Col>
        ))}
      </Row>
      <Row style={{marginTop:'10rem',flexDirection:'row',justifyContent:'center'}}>
      {(items.length === 0) && (
              <Empty description={t("NoDataFound")} style={{textAlign:'center'}} />
            )}
      </Row>




      {
        showEmployeeSignature && (
          <SignatureModal
            showSignature={showEmployeeSignature}
            handleCancel={closeSignatureModal}
            type="employee"
            loading={warningLoading}
            onDone={getEmpSignature} // Pass the callback function from the parent component
          />
        )
      }



      <Modal
        title={t("Client Signature")}
        open={showClientSignature}
        onCancel={closeSignatureModal}
        closable={true}
        footer={null}
        maskClosable={false}
      >
        {getDataForClientPin && (
          <div>
            <div>
              <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
                {t("EnterClientSignature")}
              </Title>
            </div>
            <div>
              <Input placeholder={t("Enter PIN")} onChange={handlePinChange} value={getPinValue} />
            </div>
          </div>

        )}
        {showClientSignatureBox && (
          <div>
            <div>
              <Title level={5} style={{ fontSize: '11px', color: 'red' }}>
                 {t("ClientSignature")}
              </Title>
            </div>
            <div style={{ border: '1px dotted #ccc' }}>
              <SignatureCanvas canvasProps={{ width: 460, height: 250 }} ref={clientSigCanvas} onBegin={handleDraw}/>
            </div>
            <div className="mt-25 text-right">
              <Button style={{ marginRight: '4px' }} type="danger" onClick={clearCanvas}>
             {t("Clear")}
              </Button>
              <Button disabled={isSignatureEmpty} type="primary" loading={warningLoading} onClick={() => getClientSignature(clientSigCanvas.current)}>
              {t("Done")}
              </Button>
            </div>
          </div>
        )
        }
      </Modal>
    </div>
  );
}

export default Schedular;
