import React from 'react';
import {Skeleton,Card } from 'antd';
const videos = [
  {"name": "HOW TO DO YOUR VISIT ON EVV",
 "url": "https://revivingcare.s3.us-west-2.amazonaws.com/HOW+TO+DO+YOUR+VISIT+ON+EVV/Video_Tutorial/1672853489722.mp4",
"_id": "63b5b7f24567c8ca7431c392",
"type":"home"
},
{"name": "HOW TO DO YOUR VISIT ON EVV",
"url": "https://revivingcare.s3.us-west-2.amazonaws.com/HOW+TO+DO+YOUR+VISIT+ON+EVV/Video_Tutorial/1672853489722.mp4",
"_id": "63b5b7f24567c8ca7431c392",
"type":"history"
},
 {
"description": "EVV",
"name": "Maunal Entry",
"url": "https://revivingcare.s3.us-west-2.amazonaws.com/Maunal%20Entry/Video_Tutorial/1673021608384.mp4",
"_id": "63b848a81bdfd7c24df5c7f3",
"type":"home"
}
]


const HelpContent = ({type}) => {
  const list = videos.filter(x => x.type === type);
  return (
    <>
       {/* {list.map((video) => (
        <div key={video._id} className="video-item">
          <h3>{video.name}</h3>
          <video controls className="video-player">
            <source src={video.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      ))} */}
       {list.length ? (
        list.map((video) => (
          <Card key={video._id} hoverable title={video.name} bordered={true} style={{ marginBottom: '20px' }}>
            <p>{video.description}</p> {/* Description of the video */}
            <video controls className="video-player" style={{ width: '100%' }}>
              <source src={video.url} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Card>
        ))
      ) : (
        <>
          <Skeleton.Input active={true} size="default" />
          <Skeleton.Image active={true} style={{ height: '300px', marginTop: '10px' }} />
        </>
      )}
    </>
  );
};
export default HelpContent;
// ant-skeleton-image