import React, { useState, useEffect,useRef  } from 'react';
import moment from "moment";
import socketIO from 'socket.io-client';
import ChatFooter from './ChatFooter';
import {Spin} from 'antd';
import { useParams,useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import audioFile from './../../assets/images/audio_ring_tone.wav' ;
const userType = localStorage.getItem('type') === 'e' ? '4' : '3';
const userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
const chatUrl = localStorage.getItem("chatUrl") || 'https://backend.revivingcare.us:3300/';
// this.socket = io('http://192.168.1.13:3300/', {
const socket = socketIO.connect(chatUrl,{
// const socket = socketIO.connect('https://revivingcare.net:3302/',{
  query: {
    user_id: userId?._id,
    user_type: userType,
    company_id: userId?.companyId?._id
  },
  // transports: ['websocket', 'polling']
  transports: ["websocket"],
  upgrade: false
});

const ChatBody = () => {
  const {t} = useTranslation();
    const params = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const conversation_id = params.id.split('--')[0];
    const receiverId = params.id.split('--')[1];
    const receiverName = params.id.split('--')[2];
    const userId = JSON.parse(localStorage.getItem("USER_INFO")) || {};
    const [messages, setMessage] = useState([]);
    const chatBottomRef = useRef(null);
    useEffect(() => {
      setLoading(true);
        const data = { conversation_id: conversation_id }
        socket.emit('getMessages', data);
        socket.emit('pingMe', {});
        socket.on('getMessages', (data) => {
            if (data && Array.isArray(data) && data.length) {
                data.forEach(element => {
                    element.text = element.text;
                    // element.image = "this.image";
                    element.read = element.read;
                    element.delivered = true;
                    element.sent = true;
                    element.side = element.sender_id && element.sender_id === userId._id ? 'right' : 'left';
                    element.time = element.time;
                    element.file = element.file;
                    element.type = (element.type) ? element.type.split('/')[0] : '';
                });
                console.log(data, "getMessages")
                data = data.toReversed();
                setMessage(data)
                setLoading(false);
            } else {
                setMessage([]);
                setLoading(false);
            }
        });
        socket.on('message', (res) => {
            if (res) {
              if (conversation_id === res.conversation_id) {
                res.time = res.time;
                let obj = { text: res.text,file: res.file, read: res.read, delivered: true, sent: true, side: res.sender_id && res.sender_id === userId._id ? 'right' : 'left', time: res.time, file: res.file, type: res.type }
                obj.type = (obj.type) ? obj.type.split('/')[0] : '';
                setMessage(prevMessages => [...prevMessages, obj]);
                setTimeout(() => {
                //   this.scrollToBottom();
                  if (res.sender_id !== userId._id) {
                    onAudioPlay();
                  }
                }, 10)
                console.log(res, '======================= MESSAGE SEND');
              } 
              else if (userId._id === res.sender_id && receiverId == res.receiver_id) {
                res.time = res.time;
                let obj = { name: '', text: res.text, file: res.file, read: res.read, delivered: true, sent: true, side: res.sender_id && res.sender_id === userId._id ? 'left' : 'right', time: res.time, file: res.file, type: res.type };
                obj.type = (obj.type) ? obj.type.split('/')[0] : '';
                obj.sender = 0;
                obj.side = 'left';
                setMessage(prevMessages => [...prevMessages, obj]);

                // console.log('this.conversation => ', this.conversation);
                setTimeout(() => {
                //   this.scrollToBottom();
                  if (res.sender_id !== userId._id) {
                    onAudioPlay();
                  }
                }, 10)
                console.log(res, '======================= MESSAGE SEND');
              }
            }
          });
    },[]);
    useEffect(() => {
        scrollToBottom();
      }, [messages]);

    const onAudioPlay = () => {
        // Create an audio element
        const audio = new Audio(audioFile);
        // Play the audio
        audio?.play();
      };

      const scrollToBottom = () => {
        chatBottomRef.current.scrollIntoView({ behavior: 'smooth' });
      };
    const handleLeaveChat = () => {
        history.goBack();
    };

    return (
        <>
            <header className="chat__mainHeader">
                <p>{t('Chat with')} {receiverName}</p>
                <button className="leaveChat__btn" onClick={handleLeaveChat}>
                    {t('Leave Chat')}
                </button>
            </header>
            <Spin
      size="large"
      spinning={loading}
      fullscreen
    />
            {/*This shows messages sent from you*/}
            <div className="message__container">
                {messages.map((message, index) => (
                    <>
                        {message.side === 'right' && <div key={message._id} className="message__chats">
                            {/* <p className="sender__name">{receiverName}</p> */}
                            <p className="sender__name">{userId?.fName} {userId?.lName}</p>
                            <div className="message__sender">
                                {message.type === 'audio' && <audio controls style={{ height: '37px', width: '94%', display: 'block', paddingBottom: '4px' }}>
                                    <source src={message.file} type="audio/webm" />
                                    <source src={message.file} type="audio/wav" />
                                    <source src={message.file} type="audio/ogg" />
                                    <source src={message.file} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                                }
                                {message?.type == 'image' && message?.file !=='' && <img style={{ width: '100%', height: '112px', 'marginTop': '5px' }} src={message?.file} />}
                                {message?.type == 'pdf' && message?.file !== '' && <a href="{{message?.file}}" target="_blank">View File</a>}
                                <p style={{wordWrap:'break-word'}}>{message.text}</p>
                                {/* <div dangerouslySetInnerHTML={{ __html: message.text }} /> */}
                                <small style={{ fontSize: '.7em', float: 'right', marginTop: '-4px' }}>{moment(message.time).format('hh:mm A')}</small>
                            </div>
                        </div>
                        }

                        {message.side === 'left' && <div key={message._id} className="message__chats">
                            {/* <p>{userId?.fName} {userId?.lName}</p> */}
                            <p>{receiverName}</p>
                            <div className="message__recipient">
                                {message.type === 'audio' && <audio controls style={{ height: '37px', width: '94%', display: 'block', paddingBottom: '4px' }}>
                                    <source src={message.file} type="audio/webm" />
                                    <source src={message.file} type="audio/wav" />
                                    <source src={message.file} type="audio/ogg" />
                                    <source src={message.file} type="audio/mpeg" />
                                    Your browser does not support the audio element.
                                </audio>
                                }
                                {message?.type == 'pdf' && message?.file !== '' && <a href="{{message?.file}}" target="_blank">View File</a>}
                                <p style={{wordWrap:'break-word'}}>{message.text}</p>
                                <small style={{ fontSize: '.7em', float: 'right', marginTop: '-4px' }}>{moment(message.time).format('hh:mm A')}</small>
                            </div>
                        </div>
                        }
                    </>
                ))}
                <div ref={chatBottomRef}></div>
                {/*This is triggered when a user is typing*/}
                {/* <div className="message__status">
          <p>Someone is typing...</p>
        </div> */}
            </div>
            <ChatFooter socket={socket} selectedUser={params.id}  />
        </>
    );
};

export default ChatBody;