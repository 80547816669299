import React, { useEffect, useState, useRef } from "react";
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import {
  Row,
  Col,
  Button,
  TimePicker,
  Tag,
  Alert,
  DatePicker,
  Card,
  Spin,
  Modal,
  Tooltip,
  Empty
} from "antd";
import { message as messageApi } from "antd";
import { DeleteFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { RefreshComponet } from "../../utility/UserDetails";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
const { confirm } = Modal;
function Availability() {
  const {t} = useTranslation();
  const { Meta } = Card;
  const [items, setItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [warningLoading, setWarningLoading] = useState(false);
  const [schData, setSchData] = useState({
    date: dayjs().format("YYYY-MM-DD"),
    sTime: dayjs().format("h:mm A"),
    eTime: dayjs().format("h:mm A"),
  });

  useEffect(() => {
    getAvailability();
  }, []);
  const handleChange = (values, field) => {
    if (!values) return;
    setSchData((prevState) => ({
      ...prevState,
      [field]: values,
    }));
  };

  const onAddFunc = () => {
    setWarningLoading(true);
    let obj = {
      date: dayjs(schData.date).format("YYYY-MM-DD"),
      sTime: dayjs(schData.sTime.$d).format("hh:mm A"),
      eTime: dayjs(schData.eTime.$d).format("hh:mm A"),
    };
    let endpoint = "emp/availability";
    AxiosInstance.post(endpoint, obj)
      .then(({ data }) => {
        setWarningLoading(false);

        if (data.success) {
          setIsModalOpen(false);
          messageApi.success(data.message);
          getAvailability();
        } else {
          Toaster("error", "ATTENTION", data.message);
        }
      })
      .catch((err) => {
        Toaster("error", "Error", err.message);
        setWarningLoading(false);
      });
  };

  const getAvailability = () => {
    setWarningLoading(true);
    let endpoint = "emp/availability";
    AxiosInstance.get(endpoint).then((res) => {
      if (res.data.dirtyBit) RefreshComponet(res.data.dirtyBit);
      setWarningLoading(false);
      if (res.data.success) {
        // Toaster("success", "Success", res.message);
        setItems(res.data.data.reverse());
      } else {
        setItems([]);
        Toaster("error", "Error", res.data.message);
      }
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    onAddFunc();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteAvailability = (item) => {
    confirm({
      title: "Are you sure you want to delete this availability?",
      icon: <ExclamationCircleFilled />,
      content: item?.title,
      okText: "Yes", // Change the text of the OK button to "Delete"
      cancelText: "No", // Change the text of the Cancel button to "Cancel"
      okButtonProps: { loading: warningLoading },
      onOk() {
        setWarningLoading(true);
        let endpoint = "emp/availability/";
        AxiosInstance.delete(endpoint + item._id, {})
          .then(({ data }) => {
            if (data.success) {
              messageApi.success(data.message);
              getAvailability();
            } else {
              Toaster("info", "ATTENTION", data.message);
            }
          })
          .catch((err) => {
            console.log(err);
            Toaster("error", "Error", err.message);
          })
          .finally(() => {
            // Reset loading to false when the operation is complete
            setWarningLoading(false);
          });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <div>
      <Alert
        message={
          <div>
            <span style={{ fontWeight: "bold" }}>{t('Availability')}</span>
            <Button
              type="primary"
              onClick={showModal}
              className="ant-btn-form"
              style={{ cursor: "cell", marginRight: 10, float: "inline-end" }}
            >{t('AddAvailability')}
            </Button>
          </div>
        }
        type="info"
      />

      <Spin
        spinning={warningLoading}
        fullscreen
        tip={t('Fetching Your Data')}
        size="large"
      />
      <Row gutter={[16, 16]} className="mt-3" style={{ margin: "10px 0px" }}>
        {items.map((item, index) => (
          <Col key={index} xs={24} sm={24} md={12} lg={6} xl={6}>
            <Card
              className="ant-tabs-tabpane"
              style={{
                background: "#f0f2f5",
              }}
            >
              <Meta
                title={
                  <div style={{}}>
                    <div
                      style={{
                        marginTop: 8,
                        marginLeft: "8px",
                        marginBottom: "10px",
                      }}
                    >
                      <div>
                      {dayjs(item?.date).add(1, 'day').format('MM/DD/YYYY')}
                        <Tooltip placement="bottom" title={"Delete"}>
                          <Tag
                            style={{ float: "inline-end", cursor: "pointer" }}
                            color="error"
                            icon={<DeleteFilled />}
                            onClick={() => deleteAvailability(item)}
                          ></Tag>
                        </Tooltip>
                      </div>
                      <div>
                        {item.time.map((val, index) => (
                          <span key={index}>
                            {val.sTime} - {val.eTime}
                          </span>
                        ))}
                      </div>
                      <div></div>
                    </div>
                  </div>
                }
              />
            </Card>
          </Col>
        ))}
      </Row>

      <Modal
        maskClosable={false}
        title={t('AddAvailability')}
        open={isModalOpen}
        loading={warningLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button type="primary" className="ant-btn-modal" onClick={handleOk}>
           {t('Save')}
          </Button>,
        ]}
      >
        <Row
          gutter={[16, 0]}
          style={{
            marginTop: 10,
            marginBottom: 10,
            marginLeft: 10,
            fontWeight: "bold",
          }}
        >
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
            <div>
              <label>{t('Date')}</label>
            </div>
            <DatePicker
              defaultValue={dayjs()}
              format={"MM/DD/YYYY"}
              onChange={(date) => handleChange(date, "date")}
              placeholder={t('Start Date')}
              disabledDate={(current) =>
                current && current < dayjs().startOf("day")
              }
              // disabledDate={current => current && current > dayjs().endOf('day')}
            />
          </Col>
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
            <div>
              <label>{t('StartTime')}</label>
            </div>
            <TimePicker
              defaultValue={dayjs()}
              format="hh:mm A"
              onChange={(e) => handleChange(e, "sTime")}
              placeholder={t('Start Time')}
              allowClear={true}
              use12Hours
            />
          </Col>
          <Col span={6} xs={24} sm={24} md={12} lg={6} xl={8}>
            <div>
              <label>{t('EndTime')}</label>
            </div>
            <TimePicker
              defaultValue={dayjs()}
              format="hh:mm A"
              onChange={(e) => handleChange(e, "eTime")}
              placeholder={t('Select End Time')}
              allowClear={true}
              use12Hours
            />
          </Col>
        </Row>
      </Modal>
      <Row style={{marginTop:'10rem',flexDirection:'row',justifyContent:'center'}}>
      {(items.length === 0) && (
              <Empty description={t('NoDataFound')} style={{textAlign:'center'}} />
            )}
      </Row>
    </div>
  );
}

export default Availability;
