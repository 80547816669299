import axios from "axios";
import { Toaster } from "../utility/Toaster";
const token = localStorage.getItem("token");
const axiosInstance =  axios.create({
});
axiosInstance.interceptors.request.use(
  config => {
    const evvUrl = localStorage.getItem("evvUrl") || `https://backend.revivingcare.us:3306/v1/`;
    // const evvUrl = localStorage.getItem("evvUrl") || `https://revivingcare.net:3304/v1/`;
    config.baseURL = evvUrl
    return config;
  }
)


if (token) {
  axiosInstance.defaults.headers.common["Token"] = token;
} else delete axiosInstance.defaults.headers.common["Token"];

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
 
  (error) => {
    if(error?.response?.status == '401'){
      localStorage.setItem("status",'401');
      window.dispatchEvent(new Event("storage"));
      return
    }
    if (error.response) {
      const { data } = error.response;
      Toaster('error', data.message,'');
   
    } else if (error.request) {
      Toaster('error', 'Network Error', 'Please check your internet connection');
    } else {
      Toaster('error', error.message,'');
    }
    
    // return Promise.reject(error);
  }
);

export default axiosInstance;