import React from 'react'

const Video = () => {
  return (
    <video autoPlay muted loop id="video-background" poster="poster.jpg" className="full-screen-video">
    <source
      src="https://gw.alipayobjects.com/v/huamei_gcee1x/afts/video/jXRBRK_VAwoAAAAAAAAAAAAAK4eUAQBr"
      type="video/mp4"
    />
    Your browser does not support HTML5 video.
  </video>
  )
}

export default Video