// geolocationService.js

const fetchGeolocation = async () => {
    try {
        if ("geolocation" in navigator) {
            const position = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });

            // Store in local storage
            let location ={
                lat:position.coords.latitude,
                long:position.coords.longitude
            };
            localStorage.setItem('location', JSON.stringify(location));

            return {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                error: null
            };
        } else {
            return {
                latitude: null,
                longitude: null,
                error: "Geolocation is not available in your browser."
            };
        }
    } catch (error) {
        console.error("Error fetching geolocation:", error.message);
        return {
            latitude: null,
            longitude: null,
            error: "Error fetching geolocation: " + error.message
        };
    }
};

export default fetchGeolocation;
