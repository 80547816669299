import React, { useEffect, useState,useRef} from "react";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import {DeleteTwoTone
  } from "@ant-design/icons";
import { Button, Input, Tag } from "antd";
import { Message } from "./MessageUtil";

const SignatureRecorder = ({onVoice,isRecordStared,type}) => {
  const inputRef = useRef(null);
  const recorderControls = useAudioRecorder(
    {
      noiseSuppression: true,
      echoCancellation: true,
    },
    (err) => console.table(err) // onNotAllowedOrFound
  );

  const [recordedAudio, setRecordedAudio] = useState(null);
  const [audioMimeType, setAudioMimeType] = useState(null);
  useEffect(() => {
    handleDelete();
  },[]);
  useEffect(() => {
    if(type){
    let timer;
    if (recorderControls.isRecording) {
      timer = setTimeout(() => {
        recorderControls.stopRecording();
      }, 7000); // Stop recording after 7 seconds
    }
    return () => {
      clearTimeout(timer);
    };
  }
  }, [recorderControls.isRecording]);

  const addAudioElement = (blob) => {
    const url = URL.createObjectURL(blob);
    setRecordedAudio(url);
    setAudioMimeType(blob.type);
  };

  const handleDelete = () => {
    setRecordedAudio(null);
    onVoice(null)
  };

  const handleSave = async () => {
    // Check if recordedAudio is a valid URL
    if (typeof recordedAudio === "string" && recordedAudio.trim() !== "") {
      try {
        const response = await fetch(recordedAudio);
        const blob = await response.blob();
  
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result.split(',')[1];
          // Now you have the Base64 string
          if(type === 'pin'){
            const pin = inputRef.current?.input.value.trim();
            if(pin){
              onVoice(base64String,pin,audioMimeType);
              setRecordedAudio(null);
              onVoice(null)
            }
            else{
              Message.error('Please Enter Pin')
            }
          }
          else{
            onVoice(base64String)
          }
          // Now you can pass the base64String to your API
          // For example:
          // sendBase64ToAPI(base64String);
        };
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error fetching audio or converting to Base64:", error);
      }
    } else {
      console.error("recordedAudio is not a valid URL");
    }
  };
  
  const handleRecorderClick = () => {
    isRecordStared(true)
  };
  
  

  return (
    <div onClick={handleRecorderClick}>
      <AudioRecorder
        onRecordingComplete={(blob) => addAudioElement(blob)}
        recorderControls={recorderControls}
        showVisualizer={true}
      />
      <br />
      {recordedAudio && (
        <>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <audio controls>
              <source src={recordedAudio} type="audio/wav" />
              Your browser does not support the audio element.
            </audio>
            <Button
              danger
              onClick={handleDelete}
              style={{ marginRight: "6px" }}
            >
              Delete
            </Button>
            <Button ghost type="primary" onClick={handleSave}>
              Send
            </Button>
          </div>
         {type==='pin' ? <div style={{marginTop:'15px'}}><Input type="number" placeholder="Enter Pin" ref={inputRef}/></div>:null}
        </>
      )}
    </div>
  );
};

export default SignatureRecorder;
