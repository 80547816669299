import React, { useEffect, useState } from 'react';
import AxiosInstance from "../../services/axiosInstance";
import { Toaster } from "../../utility/Toaster";
import { message as messageApi } from 'antd';
import { Row, Col, Badge, Tag , Card, Spin,Empty } from "antd";
import { CheckCircleOutlined, EyeFilled  } from '@ant-design/icons';
import dayjs from 'dayjs';
import ReactCardFlip from "react-card-flip";
import { useTranslation } from 'react-i18next';

function OpenSchedular() {
    const {t} = useTranslation();
    const { Meta } = Card;
    const [nearByList, setNearByList] = useState([]);
    const [warningLoading, setWarningLoading] = useState(false);
    const [flippedCard, setFlippedCard] = useState(null);


    useEffect(() => {
        getNearByList();
    }, []);

    const getNearByList = () => {
        setWarningLoading(true);
        let endpoint = "dispatch/schedule-listing";
        AxiosInstance.get(endpoint)
            .then(res => {
                setWarningLoading(false);
                if (res.data.success) {
                    setNearByList(res.data.data);
                } else {
                    setWarningLoading(false); 
                    setNearByList([])
                    // Toaster("error", "Error", res.data.message);
                }
            })
            .catch(error => {
                setWarningLoading(false); 
            });
    }
    const onAcceptFunc = (val) => {
        setWarningLoading(true)
        let local = "dispatch/accept-schedule";
        let obj = { "companyId": val.companyId, "id": val._id }
        AxiosInstance.post(local, obj)
            .then(({ data }) => {
                setWarningLoading(false);
                if (data.success) {
                    messageApi.success(data.message);
                    getNearByList();
                } else {
                    Toaster("error", "ATTENTION", data.message);
                }
            })
            .catch((err) => {
                Toaster("error", "Error", err.message);
                setWarningLoading(false);
            });
    }
    const onFlipBackCard = () => {
        setFlippedCard(null);
      };
      const onFlipCard = (index) => {
        setFlippedCard(index === flippedCard ? null : index);
        // onStartClickFunc(values,type)
      };
    return (
        <div>
            <Spin spinning={warningLoading} fullscreen  size='large' />
            <Row
                gutter={[16, 16]}
                className="mt-3"
                style={{ margin: "0px 0px" }}
            >
                {nearByList.map((item, index) => (
                    <Col key={index} xs={24} sm={24} md={12} lg={12} xl={8}>
<ReactCardFlip
                  className="characterCard"
                  isFlipped={index === flippedCard}
                  flipDirection="horizontal"
                >



                        <Badge.Ribbon
                            placement="start"
                            text={t(item?.status)}
                            color={
                                item.status === "Pending" ? "#91caff" : "#1E90FF"
                            }
                        >
                            
                            <Card
                                className='ant-tabs-tabpane'
                                style={{
                                    background: "#f0f2f5",
                                    borderLeftWidth: "3px",
                                    borderLeftColor:
                                        item.status === "Pending" ? "#91caff" : "#1E90FF",
                                }}

                            >
                                <Meta
                                    title={
                                        <div style={{}}>
                                            <div
                                                style={{
                                                    position: "absolute",
                                                    right: "4px",
                                                    top: "0.5rem",
                                                }}
                                            >
                                                <Tag style={{float:'inline-end'}} color="success" icon={<CheckCircleOutlined  />}  onClick={() =>onAcceptFunc(item)}>{t('Accept')}</Tag>
                                                {/* <Button
                                                    className="ant-btn-success"
                                                    type="primary"
                                                    size='small'
                                                    icon={<CheckCircleOutlined  />}
                                                    loading={warningLoading}
                                                    onClick={() =>
                                                        onAcceptFunc(item)
                                                    }
                                                    style={{ marginRight: "8px" }}
                                                >
                                                    <FormattedMessage id="Accept" />
                                                </Button> */}
                                            </div>
                                            <div
                                                style={{ marginTop: 33, marginLeft: '5px' }}>
                                                <div>
                                                 {dayjs(item?.sDate).add(1, 'day').format('MM/DD/YYYY')} - {dayjs(item?.eDate).add(1, 'day').format('MM/DD/YYYY')}
                                                </div>
                                                <div>
                                                    <span>
                                                        {" "}
                                                        {item?.sTime} - {item?.eTime}
                                                    </span>
                                                    <span
                                                        style={{ fontSize: "0.7rem", color: "#8546A7" }}
                                                    >
                                                        {" "}
                                                        {item?.serviceName}
                                                    </span>
                                                   {item?.note && (<Tag style={{float:'inline-end'}} color="blue" icon={<EyeFilled  />}  onClick={() =>onFlipCard(index)}>{t('ViewNote')}</Tag>) }
                                                </div>


                                            </div>
                                        </div>
                                    }
                                    description={
                                        <div
                                            style={{ marginBottom: "5px" }}
                                        >

                                            <div>
                                                {item?.clientId?.street} {item?.clientId?.city}
                                            </div>



                                        </div>
                                    }
                                />
                            </Card>
                        </Badge.Ribbon>

                        <Card 
                  style={{
                    background: "#f0f2f5",
                    borderColor: item.status === "Pending" ? "#91caff" : "#1E90FF",
                    borderLeftWidth: "3px",
                  }}>
                    <Col xs={24}>
                    <Meta
                    style={{ marginTop: 20 }}
                    //   title={item?.note}
                      description={
                        <>
                       {item?.note}
                          <div style={{float:'inline-end',padding:'25px 0px'}}>
                          <Tag color="error" onClick={onFlipBackCard}>{t('Close')}</Tag>
                        </div>
                        </>
                      }/>
                    </Col>
                </Card>
                        </ReactCardFlip>
                    </Col>
                ))}
            </Row>
            <Row style={{marginTop:'15rem',flexDirection:'row',justifyContent:'center'}}>
      {(nearByList.length === 0) && (
              <Empty description={t('NoDataFound')} style={{textAlign:'center'}} />
            )}
      </Row>
        </div>
    )
}

export default OpenSchedular